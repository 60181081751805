<template>
  <footer class="footer">
    <div class="container">
      <p class="text">All right reserved@pioneer medical group 2023</p>
      <div class="item text-center col-sm-6">
        <a target="_blank" href="https://crazyideaco.com">
          <h6>Made with <img class="my-heart" src="../../assets/media/image/heart.svg" alt="icon heart"> by
              Crazy
              Idea</h6>
        </a>
        <span>Think Out Of The Box</span>
      </div>
      <ul class="links">
        <li class="link">
          <router-link to="/terms">Terms & Conditions</router-link>
        </li>
        <li class="link">
          <router-link to="/privacy">privacy policy</router-link>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer-layout",
};
</script>

<style></style>
