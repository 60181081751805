<template>
  <section class="working" id="contact">
    <iframe
      :src="setting.google_link"
      width="100%"
      height="450"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
    <div class="container">
      <div class="hours">
        <h2 class="title">Working Hours</h2>
        <ul class="list_hours">
          <li v-for="(hour, index) in hours" :key="index">
            <span class="day">{{ hour.day }}</span>
            <span class="time">{{ hour.from_time }} to {{ hour.to_time }}</span>
          </li>
        </ul>
        <div class="contact_info">
          <h4 class="title">Contact info</h4>
          <div class="info">
            <i class="fa-solid fa-phone"></i>
            <a :href="`tel:${setting.phone}`">{{ setting.phone }}</a>
          </div>
          <div class="info">
            <i class="fa-solid fa-envelope"></i>
            <a :href="`mailto:${setting.email}`">{{ setting.email }}</a>
          </div>
          <ul class="social_icon">
            <li>
              <a v-if="setting.twitter != '#'" :href="setting.twitter" target="_blank"
                ><i class="fa-brands fa-twitter"></i
              ></a>
            </li>
            <li>
              <a v-if="setting.facebook != '#'" :href="setting.facebook" target="_blank"
                ><i class="fa-brands fa-facebook-f"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <a :href="`https://wa.me/+2${setting.phone}`" target="_blank" class="float_whatsapp">
    <img src="@/assets/media/image/whatsapp.png" alt="whatsapp" />
  </a>
</template>

<script>
import axios from "axios";

export default {
  name: "working-home",
  data() {
    return {
      hours: [],
      setting: {},
    };
  },
  methods: {
    fetch_working_hours() {
      axios.get("/fetch_working_hours").then(({ data }) => {
        this.hours = data.data;
        // console.log(this.hours);
      });
    },
    fetch_settings() {
      axios.get("/fetch_settings").then(({ data }) => {
        this.setting = data.data;
        // console.log(this.hours);
      });
    },
  },
  created() {
    this.fetch_working_hours();
    this.fetch_settings();
  },
};
</script>

<style></style>
