<template>
  <router-link :to="`/service/${service.id}`" class="service_card">
    <img :src="service.image" alt="service">
    <h4 class="title">{{service.title}}</h4>
    <p class="text">{{service.sub_title.substring(0, 100) + ' ...'}}</p>
    <span class="view_more">
      View more <i class="fa-solid fa-arrow-right"></i>
    </span>
  </router-link>
</template>

<script>
export default {
    props: ["service"],
};
</script>

<style></style>
