<template>
  <div class="download_certificate_pdf">
    <div
      class="download_pdf"
      id="downloadCertificate"
    >
      <img
        class="logo"
        style="
          position: absolute;
          height: 70px;
          width: 130px;
          top: 2rem;
          left: 2rem;
          opacity: 0.5;
        "
        :src="logo"
      />
      <img
        class="oeuk"
        style="
          position: absolute;
          height: 100px;
          width: 200px;
          top: 0rem;
          right: 2rem;
          opacity: .5;
        "
        :src="oeuk"
      />
      <div class="certifcate_pdf" style="position: relative">
        <p class="title" style="font-size: 2rem;margin-top: 7.5rem;margin-bottom: .5rem;text-align: center;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;color: #000;">
          Offshore Medical Certificate
        </p>
        <p
          class="text"
          style="font-size: 1.5rem; text-align: center; margin-bottom: 1.5rem"
        >
          ("Issued in accordance with Offshore Energies UK Guidelines")
        </p>
        <table
          class="table table-bordered"
          style="
            display: table;
            border-spacing: 2px;
            width: 100%;
            margin-bottom: 1rem;
            border-collapse: collapse;
            caption-side: bottom;
            vertical-align: middle;
            border-color: #dee2e6;
            border: 1px solid #dee2e6;
          "
        >
          <tbody>
            <tr style="border: 1px solid #dee2e6">
              <th
                scope="row"
                style="
                  font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                  color: #000;
                  vertical-align: middle;
                  border: 1px solid #dee2e6;
                "
              >
                Name:
              </th>
              <td
                style="
                  font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                  color: #000;
                  vertical-align: middle;
                  border: 1px solid #dee2e6;
                "
              >
                {{ track.full_name }}
              </td>
            </tr>
            <tr style="border: 1px solid #dee2e6">
              <th
                scope="row"
                style="
                  font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                  color: #000;
                  vertical-align: middle;
                  border: 1px solid #dee2e6;
                "
              >
                Date of Birth:
              </th>
              <td
                style="
                  font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                  color: #000;
                  vertical-align: middle;
                  border: 1px solid #dee2e6;
                "
              >
                {{ track.date_of_birth }}
              </td>
            </tr>
            <tr style="border: 1px solid #dee2e6">
              <th
                scope="row"
                style="
                  font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                  color: #000;
                  vertical-align: middle;
                  border: 1px solid #dee2e6;
                "
              >
                Occupation:
              </th>
              <td
                style="
                  font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                  color: #000;
                  vertical-align: middle;
                  border: 1px solid #dee2e6;
                "
              >
                {{ track.job_title }}
              </td>
            </tr>
            <tr style="border: 1px solid #dee2e6">
              <th
                scope="row"
                style="
                  font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                  color: #000;
                  vertical-align: middle;
                  border: 1px solid #dee2e6;
                "
              >
                Company:
              </th>
              <td
                style="
                  font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                  color: #000;
                  vertical-align: middle;
                  border: 1px solid #dee2e6;
                "
              >
                {{ track.company }}
              </td>
            </tr>
            <tr style="border: 1px solid #dee2e6">
              <th
                scope="row"
                style="
                  font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                  color: #000;
                  vertical-align: middle;
                  border: 1px solid #dee2e6;
                  text-align: start;
                  padding: 3rem 0.5rem;
                  position: relative;
                "
                colspan="2"
                class="text_certify"
              >
                <!-- <div
                  class="before"
                  style="
                    display: block;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0.2;
                    background-repeat: no-repeat;
                    background-position: 50% 0;
                    background-size: contain;
                  "
                ></div> -->
                This is to certify that the above named underwent assessment of
                his/her medical fitness in accordance with the Offshore Energies
                UK guideline and found in my opinion fit to offshore work without
                restrictions.
              </th>
            </tr>
            <tr style="border: 1px solid #dee2e6">
              <th
                scope="row"
                style="
                  font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                  color: #000;
                  vertical-align: middle;
                  border: 1px solid #dee2e6;
                "
              >
                Examining Physician:
              </th>
              <td
                style="
                  font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                  color: #000;
                  vertical-align: middle;
                  border: 1px solid #dee2e6;
                "
              >
                Mustafa Mahmoud (OEUK/2023/3294)
              </td>
            </tr>
            <tr style="border: 1px solid #dee2e6">
              <th
                scope="row"
                style="
                  font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                  color: #000;
                  vertical-align: middle;
                  border: 1px solid #dee2e6;
                "
              >
                OGUK / OEUK PIN no:
              </th>
              <td
                style="
                  font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                  color: #000;
                  vertical-align: middle;
                  border: 1px solid #dee2e6;
                "
              >
                OEUK/2023/3294
              </td>
            </tr>
            <tr style="border: 1px solid #dee2e6">
              <th
                scope="row"
                style="
                  font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                  color: #000;
                  vertical-align: middle;
                  border: 1px solid #dee2e6;
                "
              >
                Date of Examination:
              </th>
              <td
                style="
                  font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                  color: #000;
                  vertical-align: middle;
                  border: 1px solid #dee2e6;
                "
              >
                {{ track.reservation_date }}
              </td>
            </tr>
            <tr style="border: 1px solid #dee2e6">
              <th
                scope="row"
                style="
                  font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                  color: #000;
                  vertical-align: middle;
                  border: 1px solid #dee2e6;
                "
              >
                Date of Expiry:
              </th>
              <td
                style="
                  font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                  color: #000;
                  vertical-align: middle;
                  border: 1px solid #dee2e6;
                "
              >
                {{ track.expired_date }}
              </td>
            </tr>
            <tr style="border: 1px solid #dee2e6">
              <th
                scope="row"
                style="
                  font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                  color: #000;
                  vertical-align: middle;
                  border: 1px solid #dee2e6;
                "
              >
                Signature:
              </th>
              <td
                style="
                  font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
                  color: #000;
                  vertical-align: middle;
                  border: 1px solid #dee2e6;
                "
              >
                <div class="image" style="position: relative;width: fit-content;margin: auto;">
                  <img
                    :src="qrCodeImage"
                    class="qr"
                    alt="QR Code"
                    style="display: block; margin: 0 auto;position: absolute; top: 58px; left: 70px;height: 80px"
                  />
                  <img
                    class="signature"
                    style="width: 220px"
                    src="../assets/media/image/signature.png"
                  />
                </div>
                <!-- <p style="font-size: .9rem;text-align:center;margin-top: 0.5rem;visibility: hidden;">ss</p> -->
              </td>
            </tr>
          </tbody>
        </table>
        <a
          class="link"
          style="
            display: block;
            text-align: start;
            font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
            color: #007bc5;
            font-size: 0.9rem;
            text-decoration: underline !important;
          "
          href="https://oeuk.org.uk/doctors/find-a-registered-doctor/overseas-doctors"
          target="_blank"
          >https://oeuk.org.uk/doctors/find-a-registered-doctor/overseas-doctors</a
        >
        <div
          class="contact"
          style="
            display: flex;
            align-items: center;
            text-align: start;
            margin-top: 0.5rem;
          "
        >
          <a
            :href="`tel:${setting.phone}`"
            style="
              display: inline-flex;
              align-items: center;
              margin-inline-end: 1rem;
              text-align: start;
              font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
              color: #6b6b6b;
              font-size: 1rem;
            "
            class="phone"
          >
            <i
              class="fa-solid fa-phone"
              style="margin-inline-end: 0.5rem; color: #007bc5; font-size: 1rem"
            ></i>
            {{ setting.phone }}</a
          >
          <a
            :href="`mailto:${setting.email}`"
            style="
              display: block;
              text-align: start;
              font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
              color: #007bc5;
              font-size: 1rem;
              text-decoration: underline !important;
            "
            class="mail"
            >{{ setting.email }}</a
          >
        </div>
      </div>
    </div>
    <button class="btn PrimaryButton" @click="downloadPDF">Download as pdf</button>
  </div>
</template>
<script>
import axios from "axios";
import { ref, onMounted } from 'vue';
import html2pdf from "html2pdf.js";
import QRCode from "qrcode";
import { useRoute } from 'vue-router';

export default {
  name: "certificate-pdf",
  data() {
    return {
      setting: {},
      track: {},
      logo: require("../assets/media/image/logo.png"),
      oeuk: require("../assets/media/image/oeuk.png"),
      signature: require("../assets/media/image/signature.png"),
      elite: require("../assets/media/image/elite.png"),
    };
  },
  setup() {
    const qrCodeImage = ref('');

    onMounted(() => {
      const route = useRoute();
      const routeId = route.params.id;
      const qrCodeValue = `https://pmgoccuhealth.com/medical_certificate/${routeId}`;

      QRCode.toDataURL(qrCodeValue)
        .then((url) => {
          qrCodeImage.value = url;
        })
        .catch((error) => {
          console.error('Error generating QR code:', error);
        });
    });

    return {
      qrCodeImage,
    };
  },
  methods: {
    downloadPDF() {
      const element = document.getElementById("downloadCertificate"); // Replace 'myDiv' with the ID of your div element
      const option = {
        margin: 0,
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        preserveCSS: true,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 }, // Increase scale to improve text quality
      }
      html2pdf()
        .from(element)
        .set(option)
        .save("Certificate.pdf");
    },
    fetch_settings() {
      axios.get("/fetch_settings").then(({ data }) => {
        this.setting = data.data;
        // console.log(this.setting);
      });
    },
    search_serial_number() {
      axios
        .get(`/search_serial_number/${this.$route.params.id}`)
        .then(({ data }) => {
          this.status = data.status;
          this.track = data.data;
          // console.log(this.track)
        })
        .catch((error) => {
          console.log(error);
          return error;
        })
        .finally(() => {
          //Perform action in always
        });
    },
  },
  created() {
    this.fetch_settings();
    this.search_serial_number();
  },
};
</script>

<style scoped>
  .download_certificate_pdf {
    position: relative;
    width: 70%;
    margin: 5rem auto;
    border: 1px solid #212529;
    padding: 2.5rem;
  }
  .download_pdf {
    width: 90%;
    margin: 0 auto;
  }
  .download_certificate_pdf .PrimaryButton{
    display: block;
    margin-inline-start: auto;
    margin-top: 1.5rem;
  }
  .before {
    background-image: url('../assets/media/image/elite.png');
  }
  @media only screen and (max-width: 991.98px) {
    .download_certificate_pdf {
      width: 19cm !important;
    }
  }
  @media only screen and (max-width: 600px) {
    .download_certificate_pdf * {
      font-size: .8rem;
    }
    .download_pdf {
      width: 100%;
    }
    .download_certificate_pdf .title {
      font-size: 1.5rem !important;
    }
    .download_certificate_pdf .text {
      font-size: 1rem !important;
    }
    .download_certificate_pdf {
      width: 100%;
      padding: 0;
    }
    .qr {
      width: 120px !important;
      height: 120px !important;
    }
    .logo {
      height: 50px !important;
      width: 90px !important;
    }
    .oeuk {
      height: 80px !important;
      width: 120px !important;
    }
    .download_certificate_pdf .PrimaryButton {
      margin-inline-end: 1rem;
      margin-bottom: 1rem;
    }
    .download_certificate_pdf .contact {
      flex-direction: column;
      align-items: start !important;
    }
  }
</style>
