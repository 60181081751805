<template>
  <router-link :to="`blog/${blogHeader.id}`" class="blog_card">
    <img :src="blogHeader.image" :alt="blogHeader.title" />
    <div class="content_blog">
        <p class="date">{{blogHeader.date}}</p>
        <h5 class="title">{{blogHeader.title}}</h5>
        <p class="text" v-html="blogHeader.text"></p>
        <span class="read_more">Read More <i class="fa-regular fa-square-caret-right"></i></span>
    </div>
  </router-link>
</template>

<script>
export default {
    name: "blog-card",
    props: ["blogHeader"]
};
</script>

<style></style>
