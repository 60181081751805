<template>
  <section class="features_section">
    <div class="container">
        <ul class="list_features">
            <li class="list_feature" v-for="(feature, index) in features" :key="index">
                <img :src="feature.image" :alt="feature.title">
                <h5 class="title">{{feature.title}}</h5>
                <p class="text" v-html="feature.text"></p>
            </li>
        </ul>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
    name: "features-home",
    data() {
        return {
            features: []
        }
    },
    methods: {
        fetch_features() {
            axios.get("/fetch_features").then(({ data }) => {
                this.features = data.data;
                // console.log(this.about);
            });
        },
    },
    created() {
        this.fetch_features()
    }
}
</script>

<style>

</style>