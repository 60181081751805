<template>
  <form class="track" v-on:submit.prevent="SubmitForm">
    <h4 class="title">{{ $t("Search_For_Certificate") }}</h4>
    <p class="text">{{ $t("enter_serial_no") }}</p>
    <input
      type="number"
      v-model="serial_no"
      name=""
      id=""
      class="form-control"
      placeholder="enter the serial No."
    />
    <button type="submit" class="btn PrimaryButton">{{ $t("Search") }}</button>
  </form>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
// import { ref } from 'vue';
import html2pdf from "html2pdf.js";
// import QRCode from './QRCode.vue';
import { ref, onMounted } from "vue";
import QRCode from "qrcode";
import localLogo from "../../../assets/media/image/logo_certifcate.png";
import localoeuk from "../../../assets/media/image/oeuk.png";
import localElite from "../../../assets/media/image/elite.png";
import localSignature from "../../../assets/media/image/signature.png";

export default {
  data() {
    return {
      // setting: {},
      logo: require("../../../assets/media/image/logo_certifcate.png"),
      oeuk: require("../../../assets/media/image/oeuk.png"),
      signature: require("../../../assets/media/image/signature.png"),
      elite: require("../../../assets/media/image/elite.png"),
    };
  },
  setup() {
    const divContent = ref(null);

    const downloadAsPDF = () => {
      const element = divContent.value;

      html2pdf()
        .set({ html2canvas: { scale: 4 } }) // Adjust the scale as needed for better quality
        .from(element)
        .save("download.pdf");
    };

    const qrCodeImage = ref("");
    const serial_no = ref("");
    const imageLogo = ref(localLogo);
    const imageoeuk = ref(localoeuk);
    const imageElite = ref(localElite);
    const imageSignature = ref(localSignature);
    const setting = ref();

    onMounted(async () => {
      try {
        const response = await axios.get("fetch_settings");
        setting.value = response.data;
        // console.log(setting.value)
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    });

    const downloadPDF = () => {
      const element = document.getElementById("downloadCertificate"); // Replace 'myDiv' with the ID of your div element
      html2pdf()
        .from(element)
        .set({ preserveCSS: true })
        .save("Certificate.pdf");
    };

    const SubmitForm = async (event) => {
      const qrCodeValue = `https://pmgoccuhealth.com/certificate/${serial_no.value}`; // Use the form value prop as the value for generating the QR code
      // console.log(qrCodeValue)

      QRCode.toDataURL(qrCodeValue)
        .then((url) => {
          qrCodeImage.value = url;
          // console.log(qrCodeImage)
        })
        .catch((error) => {
          console.error("Error generating QR code:", error);
        });
      event.preventDefault();

      try {
        const serial_number = { serial_no: serial_no.value };
        const response = await axios.post(
          "/search_by_serial_number",
          serial_number
        );
        // console.log(response.data.data);
        if (response.data.status == true) {
          if (response.data.data.status == 0) {
            Swal.fire({
              title:
                "Thanks For your Application, we will contact you soon with further details.",
              text: "Modal with a custom image.",
              imageUrl:
                "https://cdn-icons-png.flaticon.com/512/5610/5610944.png",
              imageWidth: 110,
              imageHeight: 110,
              imageAlt: "Custom image",
              html:
                `<p class="title mb-2">Serial No : ${response.data.data.serial_no}</p>` +
                '<p class="text">Note: Examination will take 1 hour.</p>' +
                `<div class="email"><p class="text">If you have any Questions, contact us on: </p><a href="mailto:info@pmgoccuhealth.com">info@pmgoccuhealth.com</a></div> `,
              showConfirmButton: false,
            });
          } else if (response.data.data.status == 1) {
            Swal.fire({
              title: "Your booking has been successfully scheduled",
              imageUrl:
                "https://cdn-icons-png.flaticon.com/512/5610/5610944.png",
              imageWidth: 110,
              imageHeight: 110,
              imageAlt: "Success image",
              html:
                `<div class="scheduled">` +
                `<div class="date">` +
                `<p class="mb-3"><i class="fa-solid fa-calendar-days"></i> Reservation date : ${response.data.data.reservation_date}</p>` +
                `<p class="mb-0"><i class="fa-solid fa-clock"></i> Reservation time : ${response.data.data.reservation_time}</p></div>` +
                '<p class="text">For more information</p>' +
                `<a href="tel:${setting.value.data.phone}">Contact Us</a></div>`,
              showConfirmButton: false,
            });
          } else if (response.data.data.status == 2) {
            // if (response.data.data.certificate_type == 1) {
            //   Swal.fire({
            //     customClass: {
            //       popup: "certificate-popup",
            //       confirmButton: "btn PrimaryButton",
            //     },
            //     html:
            //       '<div class="download_pdf" id="downloadCertificate" style="width: 80%; margin: 0 auto">' +
            //       `<img class="logo" style="position: absolute;
            //                                 height: 70px;
            //                                 width: 130px;
            //                                 top: 2rem;
            //                                 left: 3rem;
            //                                 opacity: .2;" src="${imageLogo.value}" />` +
            //       `<img class="oeuk" style="position: absolute;
            //                                 height: 100px;
            //                                 width: 200px;
            //                                 top: 0rem;
            //                                 opacity: 0.2;
            //                                 right: 2rem;" src="${imageoeuk.value}" />` +
            //       `<div class="certifcate_pdf" style="position: relative;">` +
            //       `<p class="title" style="font-size: 2rem;margin-top: 7.5rem;text-align: center;font-family: Arial Black, Arial Bold, Gadget, sans-serif;color: #000;">"Offshore Medical Certificate"</p>` +
            //       `<p class="text" style="font-size: 1.2rem;text-align: center;margin-bottom: 1.5rem;">("Issued in accordance with Offshore Energies UK Guidelines")</p>` +
            //       '<table class="table table-bordered" style="display: table;border-spacing: 2px;width: 100%;margin-bottom: 1rem;border-collapse: collapse;caption-side: bottom;vertical-align: middle;border-color: #dee2e6; border:1px solid #dee2e6">' +
            //       "<tbody>" +
            //       '<tr style="border: 1px solid #dee2e6">' +
            //       '<th scope="row" style="font-family: Arial Black, Arial Bold, Gadget, sans-serif;color: #000;vertical-align: middle; border: 1px solid #dee2e6;">Name: </th>' +
            //       `<td style="font-family: Arial Black, Arial Bold, Gadget, sans-serif;color: #000;vertical-align: middle; border: 1px solid #dee2e6;">${response.data.data.full_name}</td>` +
            //       "</tr>" +
            //       '<tr style="border: 1px solid #dee2e6">' +
            //       '<th scope="row" style="font-family: Arial Black, Arial Bold, Gadget, sans-serif;color: #000;vertical-align: middle; border: 1px solid #dee2e6;">Date of Birth: </th>' +
            //       `<td style="font-family: Arial Black, Arial Bold, Gadget, sans-serif;color: #000;vertical-align: middle; border: 1px solid #dee2e6;">${response.data.data.date_of_birth}</td>` +
            //       "</tr>" +
            //       '<tr style="border: 1px solid #dee2e6">' +
            //       '<th scope="row" style="font-family: Arial Black, Arial Bold, Gadget, sans-serif;color: #000;vertical-align: middle; border: 1px solid #dee2e6;">Occupation: </th>' +
            //       `<td style="font-family: Arial Black, Arial Bold, Gadget, sans-serif;color: #000;vertical-align: middle; border: 1px solid #dee2e6;">${response.data.data.job_title}</td>` +
            //       "</tr>" +
            //       '<tr style="border: 1px solid #dee2e6">' +
            //       '<th scope="row" style="font-family: Arial Black, Arial Bold, Gadget, sans-serif;color: #000;vertical-align: middle; border: 1px solid #dee2e6;">Company: </th>' +
            //       `<td style="font-family: Arial Black, Arial Bold, Gadget, sans-serif;color: #000;vertical-align: middle; border: 1px solid #dee2e6;">${response.data.data.company}</td>` +
            //       "</tr>" +
            //       '<tr style="border: 1px solid #dee2e6">' +
            //       '<th scope="row" style="font-family: Arial Black, Arial Bold, Gadget, sans-serif;color: #000;vertical-align: middle; border: 1px solid #dee2e6; text-align: start;padding: 3rem .5rem;position: relative;" colspan="2" class="text_certify">' +
            //       `<div class="before" style="display: block;position: absolute;left: 0;right: 0;top: 0;bottom: 0;width: 100%;height: 100%;opacity: .2;background-image: url(${imageElite.value});background-repeat: no-repeat;background-position: 50% 0;background-size: contain;"></div>` +
            //       "This is to certify that the above named underwent assessment of his/her medical fitness in accordance with the Offshore Energies UK guideline and found in my opinion fit to offshore work without restrictions.</th>" +
            //       "</tr>" +
            //       '<tr style="border: 1px solid #dee2e6">' +
            //       '<th scope="row" style="font-family: Arial Black, Arial Bold, Gadget, sans-serif;color: #000;vertical-align: middle; border: 1px solid #dee2e6;">Examining Physician: </th>' +
            //       `<td style="font-family: Arial Black, Arial Bold, Gadget, sans-serif;color: #000;vertical-align: middle; border: 1px solid #dee2e6;">Mustafa Mahmoud</td>` +
            //       "</tr>" +
            //       '<tr style="border: 1px solid #dee2e6">' +
            //       '<th scope="row" style="font-family: Arial Black, Arial Bold, Gadget, sans-serif;color: #000;vertical-align: middle; border: 1px solid #dee2e6;">OGUK / OEUK PIN no: </th>' +
            //       `<td style="font-family: Arial Black, Arial Bold, Gadget, sans-serif;color: #000;vertical-align: middle; border: 1px solid #dee2e6;">OEUK/2023/3294</td>` +
            //       "</tr>" +
            //       '<tr style="border: 1px solid #dee2e6">' +
            //       '<th scope="row" style="font-family: Arial Black, Arial Bold, Gadget, sans-serif;color: #000;vertical-align: middle; border: 1px solid #dee2e6;">Date of Examination: </th>' +
            //       `<td style="font-family: Arial Black, Arial Bold, Gadget, sans-serif;color: #000;vertical-align: middle; border: 1px solid #dee2e6;">${response.data.data.reservation_date}</td>` +
            //       "</tr>" +
            //       '<tr style="border: 1px solid #dee2e6">' +
            //       '<th scope="row" style="font-family: Arial Black, Arial Bold, Gadget, sans-serif;color: #000;vertical-align: middle; border: 1px solid #dee2e6;">Date of Expiry: </th>' +
            //       `<td style="font-family: Arial Black, Arial Bold, Gadget, sans-serif;color: #000;vertical-align: middle; border: 1px solid #dee2e6;">${response.data.data.expired_date}</td>` +
            //       "</tr>" +
            //       '<tr style="border: 1px solid #dee2e6">' +
            //       '<th scope="row" style="font-family: Arial Black, Arial Bold, Gadget, sans-serif;color: #000;vertical-align: middle; border: 1px solid #dee2e6;">Signature: </th>' +
            //       `<td style="font-family: Arial Black, Arial Bold, Gadget, sans-serif;color: #000;vertical-align: middle; border: 1px solid #dee2e6;">` +
            //       '<div class="image" style="position: relative;width: fit-content;margin: auto;">' +
            //       `<img src="${qrCodeImage.value}" class="qr" style="display: block; margin: 0 auto;position: absolute; top: 65px; left: 65px;height: 120px" alt="QR Code" />` +
            //       `<img class="signature" style="width: 250px" src="${imageSignature.value}"/>` +
            //       "</div>" +
            //       `</td>` +
            //       "</tr>" +
            //       "</tbody>" +
            //       "</table>" +
            //       '<a class="link" style="display: block;text-align: start;font-family: Arial Black, Arial Bold, Gadget, sans-serif;color: #007bc5;font-size: .9rem;text-decoration: underline !important;" href="https://oeuk.org.uk/oeuk-register-of-examining-uk-doctors-by-name-or-pin-table/" target="_blank">https://oeuk.org.uk/oeuk-register-of-examining-uk-doctors-by-name-or-pin-table/</a>' +
            //       '<div class="contact" style="display:flex; align-items:center;text-align: start;margin-top: 0.5rem;">' +
            //       `<a href="tel:${setting.value.data.phone}" style="display: inline-flex;align-items: center;margin-inline-end: 1rem;text-align: start;font-family: Arial Black, Arial Bold, Gadget, sans-serif;color: #6b6b6b;font-size: 1rem;" class="phone">` +
            //       `<i class="fa-solid fa-phone" style="margin-inline-end: .5rem;color: #007bc5;font-size: 1rem;"></i>` +
            //       `${setting.value.data.phone}</a>` +
            //       `<a href="mailto:${setting.value.data.email}" style="display: block;text-align: start;font-family: Arial Black, Arial Bold, Gadget, sans-serif;color: #007bc5;font-size: 1rem;text-decoration: underline !important;" class="mail">${setting.value.data.email}</a>` +
            //       "</div>" +
            //       `</div>` +
            //       `</div>`,
            //     confirmButtonText: "Download as pdf",
            //     preConfirm: () => {
            //       return new Promise((resolve) => {
            //         resolve(downloadPDF());
            //       });
            //     },
            //   });
            // }
            // console.log(response.data.data.certificate_type)
            if (response.data.data.certificate_type == 1) {
              window.open(`${window.location.origin}/medical_certificate/${serial_no.value}`, '_blank');
            }
            else if (response.data.data.certificate_type == 2) {
              window.open(`${window.location.origin}/fitness_certificate/${serial_no.value}`, '_blank');
            }
          } else if (response.data.data.status == 3) {
            Swal.fire({
              imageUrl:
                "https://cdn-icons-png.flaticon.com/512/10308/10308565.png",
              imageWidth: 100,
              imageHeight: 100,
              imageAlt: "Error image",
              customClass: {
                confirmButton: "btn PrimaryButton",
              },
              confirmButtonText: "Back To Home",
              title: "Sorry, the certificate has not been issued yet",
              text: "please try again later and search for your certificate",
            });
          }
        } else {
          Swal.fire({
            imageUrl:
              "https://cdn-icons-png.flaticon.com/512/10308/10308565.png",
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: "Error image",
            customClass: {
              confirmButton: "btn PrimaryButton",
            },
            confirmButtonText: "Try Again",
            title: "Sorry, this is a fake serial number please try again",
          });
        }

        // Reset form values
        serial_no.value = "";
      } catch (error) {
        Swal.fire({
          imageUrl: "https://cdn-icons-png.flaticon.com/512/10308/10308565.png",
          imageWidth: 100,
          imageHeight: 100,
          imageAlt: "Error image",
          customClass: {
            confirmButton: "btn PrimaryButton",
          },
          confirmButtonText: "Try Again",
          title: "Sorry, this is a fake serial number please try again",
        });
        return error;
      }
    };

    return {
      divContent,
      downloadAsPDF,
      qrCodeImage,
      SubmitForm,
      serial_no,
      imageLogo,
      imageoeuk,
      imageElite,
      imageSignature,
      setting,
      downloadPDF,
    };
  },
};
</script>
