<template>
  <section class="blogs">
    <div class="container">
        <h1 class="title">Our Medical News</h1>
        <p class="text">follow us for more interesting news</p>
        <div class="row">
          <div class="col-lg-6 col-md-12 col-12">
            <router-link :to="`blog/${firstBlog.id}`" class="card_home_main_blog">
              <div class="content-overlay"></div>
              <img :src="firstBlog.image" :alt="firstBlog.title">
              <div class="content_blog fadeIn-bottom">
                <p class="date">{{firstBlog.date}}</p>
                <h4 class="title">{{firstBlog.title}}</h4>
                <p class="text" v-html="firstBlog.text"></p>
                <span class="read_more">Read More <i class="fa-regular fa-square-caret-right"></i></span>
              </div>
            </router-link>
          </div>
          <div class="col-lg-6 col-md-12 col-12">
            <router-link :to="`blog/${blog.id}`" class="card_home_blog" v-for="(blog, index) in blogs" :key="index">
              <img :src="blog.image" :alt="blog.title">
              <div class="content_blog">
                <p class="date">{{blog.date}}</p>
                <h4 class="title">{{blog.title}}</h4>
                <p class="text" v-html="blog.text.substring(0, 300) + ' ...'"></p>
                <span class="read_more">Read More <i class="fa-regular fa-square-caret-right"></i></span>
              </div>
            </router-link>
          </div>
        </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
    name: "blog-home",
    data() {
        return {
          firstBlog: [],
          blogs: []
        }
    },
    methods: {
      fetch_home_blogs() {
        axios.get("/fetch_home_blogs").then(({ data }) => {
          this.firstBlog = data.data[0];
          this.blogs = data.data;
          this.blogs.shift(); // Remove the first object
          // console.log(this.blogs);
        });
      },
    },
    created() {
      this.fetch_home_blogs();
    }
}
</script>

<style>

</style>