<template>
  <div class="download_certificate_pdf" style="width: 21cm;">
    <div
      class="download_pdf"
      id="downloadCertificate"
    >
      <div class="a4" style="min-height: 29.7cm">
        <div class="d-flex justify-content-between align-items-center" style="background-color: #0779B8;padding: 0.36rem 1rem;">
        <h4 style="text-align: start;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;color: #fff;width: 70%;line-height: 1.5;font-size: 1.25rem">FITNESS FOR WORK MEDICAL CERTIFTCATE</h4>
        <img
            class="logo"
            style="
            opacity: 1;
            height: 50px;
            width: 80px;
            "
            :src="logo"
        />
        </div>
        <div class="certifcate_pdf" style="position: relative;border: 1px solid #212529;padding: 1.5rem 1rem .6rem">
          <ul class="personal_details" style="display: flex; flex-wrap: wrap;border: 1px solid #576065;list-style: none;">
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;font-size: .9rem">Name : <span style="font-family: 'Almarai', sans-serif">{{track.full_name}}</span></li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065;font-size: .9rem">Country of origin : <span style="font-family: 'Almarai', sans-serif">{{track.country_of_origin}}</span></li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;font-size: .9rem">Date of birth : <span style="font-family: 'Almarai', sans-serif">{{track.date_of_birth}}</span></li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065;font-size: .9rem">Country of work : <span style="font-family: 'Almarai', sans-serif">{{track.country_of_work}}</span></li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;font-size: .9rem">Job Applying for : <span style="font-family: 'Almarai', sans-serif">{{track.jop_applying_for}}</span></li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065;font-size: .9rem">Company : <span style="font-family: 'Almarai', sans-serif">{{track.company}}</span></li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;font-size: .9rem">Workplace settings : <span style="font-family: 'Almarai', sans-serif">{{track.workplace_setting}}</span></li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065;font-size: .9rem">Valid for work in : <span style="font-family: 'Almarai', sans-serif">{{track.valid_for_work_in}}</span></li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;font-size: .9rem">Accommodations : <span style="font-family: 'Almarai', sans-serif">{{track.accommodations}}</span></li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065;font-size: .9rem">Recommendation  : <span style="font-family: 'Almarai', sans-serif">{{track.recommendations}}</span></li>
          </ul>
          <h5 style="width: 95%;margin: 1rem auto;text-align: center;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif">Medically fit to fulfil the “Inherent requirements of the Job applied for” without restrictions.</h5>
          <h6 class="title" style="color: #021B29;background-color: rgba(7, 121, 184, .1);font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;padding: .7rem 0;margin-bottom: 1rem;text-align: center">Examinations comprised the following:</h6>
          <ul class="personal_details" style="display: flex; flex-wrap: wrap;border: 1px solid #576065;list-style: none;">
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;font-size: .9rem">1. Physical examination</li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065;font-size: .9rem">2. Spirometry</li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;font-size: .9rem">3. Eye examination</li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065;font-size: .9rem">4. ECG</li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;font-size: .9rem">5. Audiogram</li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065;font-size: .9rem">06.Drug and or Alcohol test</li>
          </ul>
          <h6 class="title" style="color: #021B29;background-color: rgba(7, 121, 184, .1);font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;padding: .7rem 0;margin-bottom: 1rem;text-align: center">Laboratory Test: As required of your employer.</h6>
          <ul class="personal_details" style="display: flex; flex-wrap: wrap;border: 1px solid #576065;list-style: none;margin-bottom: 0">
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;font-size: .9rem">1. CBC/Ferritin</li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065;font-size: .9rem">2. Fibrinogen/ ESR</li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;font-size: .9rem">3. FBS/ HbAlC</li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065;font-size: .9rem">4. Urea/Creatinine/Homocysteine </li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;font-size: .9rem">5. Lipid Profile</li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065;font-size: .9rem">6. ProteinElectrophoresis</li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;font-size: .9rem">7. SGOT/SGOT+ GGT</li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065;font-size: .9rem">8. PT/PTT/INR </li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;font-size: .9rem">9. Bilirubin(Total/Direct/lndirect)</li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065;font-size: .9rem">10. Urine analysis/ Alcohol </li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;font-size: .9rem">11. HIV/VDRL/Measles IgM&IgG</li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065;font-size: .9rem">12. HAV/HCV/HBs  Ag& Ab</li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;font-size: .9rem">Occupational Physician:</li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065;font-size: .9rem">Mustafa Mahmoud (OEUK/2023/3294)</li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;font-size: .9rem">Date:</li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065;font-size: .9rem">{{track.reservation_date}}</li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;font-size: .9rem">Expiry Date:</li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065;font-size: .9rem">{{track.expired_date}}</li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">Signature:</li>
            <li class="list" style="width: 50%;padding: 0.36rem 1rem ;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">
              <div class="image" style="position: relative;width: fit-content;margin: auto;">
                <img
                  :src="qrCodeImage"
                  class="qr"
                  alt="QR Code"
                  style="display: block; margin: 0 auto;position: absolute; top: 58px; left: 70px;height: 80px"
                />
                <img
                  class="signature"
                  style="width: 220px"
                  src="../assets/media/image/signature.png"
                />
              </div>
              <!-- <p style="font-size: .9rem;text-align:center;margin-top: 0.5rem;visibility: hidden;">ss</p> -->
            </li>
          </ul>
        </div>
      </div>

      <div class="a4" style="min-height: 29.7cm">
      <div class="d-flex justify-content-center align-items-center" style="background-color: #0779B8;padding: 0.36rem 1rem;" v-if="track.fit == 1">
        <h4 style="text-align: start;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;color: #fff;width: 90%;line-height: 1.5;margin: 0 auto;text-align: center">Full Medical Examination Report</h4>
      </div>
      <div class="certifcate_pdf" v-if="track.fit == 1" style="position: relative;border: 1px solid #212529;padding: 1.5rem 1rem 0">
        <div class="image" style="position: absolute;width: fit-content;margin: auto;top: 0;right: 0;">
          <img
            :src="qrCodeImage"
            class="qr"
            alt="QR Code"
            style="display: block; margin: 0 auto;position: absolute; top: 40px; left: 50px;height: 70px"
          />
          <img
            class="signature"
            style="width: 170px;opacity: 0.7"
            src="../assets/media/image/signature.png"
          />
        </div>
        <ul class="personal_details" style="display: flex; flex-wrap: wrap;border: 1px solid #576065;list-style: none;">
          <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">Name : </li>
          <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">{{track.full_name}}</li>
          <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">Passport Number : </li>
          <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">{{track.passport_id}}</li>
          <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">Height : </li>
          <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">{{track.height}}</li>
          <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">Weight : </li>
          <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">{{track.weight}}</li>
          <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">BMI : </li>
          <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">{{track.BMI}}</li>
          <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">BP : </li>
          <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">{{track.BP}}</li>
          <li class="list" style="width: 50%;padding: 0.36rem 1rem ;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">Pulse : </li>
          <li class="list" style="width: 50%;padding: 0.36rem 1rem ;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">{{track.pulse}}</li>
        </ul>
        <h6 class="title" style="color: #021B29;background-color: rgba(7, 121, 184, .1);font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;padding: .7rem 0;margin-bottom: 1rem;text-align: center">Respiratory Examination</h6>
        <ul class="personal_details" style="display: flex; flex-wrap: wrap;border: 1px solid #576065;list-style: none;">
          <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">FEV1: {{track.FEV1}}</li>
          <li class="list" style="width: 50%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">FEV1/FVC: {{Math.round((track.FEV1 / track.FVC) * 100)}} %</li>
          <li class="list" style="width: 50%;padding: 0.36rem 1rem ;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">FVC: {{track.FVC}}</li>
          <li class="list" style="width: 50%;padding: 0.36rem 1rem ;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065"></li>
        </ul>
        <!-- Vision – Distance -->
        <h6 class="title" style="color: #021B29;background-color: rgba(7, 121, 184, .1);font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;padding: .7rem 0;margin-bottom: 1rem;text-align: center">Vision – Distance</h6>
        <ul class="personal_details" style="display: flex; flex-wrap: wrap;border: 1px solid #576065;list-style: none;">
          <li class="list" style="width: 40%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">without Glasses / lenses</li>
          <li class="list" style="width: 40%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">with Glasses / lenses</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">BOTH</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">R</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">{{track.without_glasses_right}}</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">R</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">{{track.with_glasses_right}}</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">{{track.without_glasses_both}}</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">L</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">{{track.without_glasses_left}}</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">L</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">{{track.with_glasses_left}}</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">{{track.with_glasses_both}}</li>
        </ul>
        <!-- Vision – Distance -->

        <!-- Vision – Near -->
        <h6 class="title" style="color: #021B29;background-color: rgba(7, 121, 184, .1);font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;padding: .7rem 0;margin-bottom: 1rem;text-align: center">Vision – Near</h6>
        <ul class="personal_details" style="display: flex; flex-wrap: wrap;border: 1px solid #576065;list-style: none;">
          <li class="list" style="width: 40%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">without Glasses / lenses</li>
          <li class="list" style="width: 40%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">with Glasses / lenses</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">BOTH</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">R</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">{{track.without_glasses_right_near}}</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">R</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">{{track.with_glasses_right_near}}</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">{{track.without_glasses_both_near}}</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">L</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">{{track.without_glasses_left_near}}</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">L</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">{{track.with_glasses_left_near}}</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">{{track.with_glasses_both_near}}</li>
        </ul>
        <!-- Vision – Near -->

        <!-- Others -->
        <h6 class="title" style="color: #021B29;background-color: rgba(7, 121, 184, .1);font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;padding: .7rem 0;margin-bottom: 1rem;text-align: center">Others</h6>
        <ul class="personal_details" style="display: flex; flex-wrap: wrap;border: 1px solid #576065;list-style: none;">
          <li class="list" style="width: 40%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;"></li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">Normal</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">Abnormal</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">Comment</li>
          <!-- Colour vision -->
          <li class="list" style="width: 40%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">Colour vision</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.colour_vision == 1"></i></li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.colour_vision == 0"></i></li>
          <li class="list" style="width: 20%;padding: 0.5rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Almarai', sans-serif; font-size: 0.8rem; border-inline-start: 1px solid #576065">{{track.colour_vision_comment}}</li>
          <!-- Colour vision -->

          <!-- AUDIOMETRIC -->
          <li class="list" style="width: 40%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">AUDIOMETRIC</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.AUDIOMETRIC == 1"></i></li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.AUDIOMETRIC == 0"></i></li>
          <li class="list" style="width: 20%;padding: 0.5rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Almarai', sans-serif; font-size: 0.8rem; border-inline-start: 1px solid #576065">{{track.AUDIOMETRIC_comment}}</li>
          <!-- AUDIOMETRIC -->

          <!-- SUBSTANCE ABUSE SCREENING -->
          <li class="list" style="width: 40%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">SUBSTANCE ABUSE SCREENING</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.SUBSTANCE_ABUSE == 1"></i></li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.SUBSTANCE_ABUSE == 0"></i></li>
          <li class="list" style="width: 20%;padding: 0.5rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Almarai', sans-serif; font-size: 0.8rem; border-inline-start: 1px solid #576065">{{track.SUBSTANCE_ABUSE_comment}}</li>
          <!-- SUBSTANCE ABUSE SCREENING -->

          <!-- STOOL CULTURE (Catering Crew) -->
          <li class="list" style="width: 40%;padding: 0.36rem 1rem ;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">STOOL CULTURE (Catering Crew)</li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.STOOL_CULTURE == 1"></i></li>
          <li class="list" style="width: 20%;padding: 0.36rem 1rem ;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065"><i class="fa-solid fa-check" v-if="track.STOOL_CULTURE == 0"></i></li>
          <li class="list" style="width: 20%;padding: 0.5rem ;font-size: 0.9rem;font-family: 'Almarai', sans-serif; font-size: 0.8rem; border-inline-start: 1px solid #576065">{{track.STOOL_CULTURE_comment}}</li>
          <!-- STOOL CULTURE (Catering Crew) -->
        </ul>
        <!-- Others -->
        </div>
      </div>

      <div class="a4">
        <div class="certifcate_pdf" v-if="track.fit == 1" style="min-height: 29.7cm;position: relative;border: 1px solid #212529;padding: 1.5rem 1rem">
          <!-- Urinalysis -->
          <h6 class="title" style="color: #021B29;background-color: rgba(7, 121, 184, .1);font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;padding: .7rem 0;margin-bottom: 1rem;margin-top: 0rem; text-align: center">Urinalysis</h6>
          <ul class="personal_details" style="display: flex; flex-wrap: wrap;border: 1px solid #576065;list-style: none;position: relative">
            <div class="image" style="position: absolute;width: fit-content;margin: auto;top: 0;right: 0;">
              <img
                :src="qrCodeImage"
                class="qr"
                alt="QR Code"
                style="display: block; margin: 0 auto;position: absolute; top: 40px; left: 50px;height: 70px"
              />
              <img
                class="signature"
                style="width: 170px;opacity: 0.7"
                src="../assets/media/image/signature.png"
              />
            </div>
            <li class="list" style="width: 40%;padding: 0.36rem 1rem ;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">Protein : {{track.protein}}</li>
            <li class="list" style="width: 30%;padding: 0.36rem 1rem ;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">Blood : {{track.blood}}</li>
            <li class="list" style="width: 30%;padding: 0.36rem 1rem ;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">Glucose : {{track.glucose}}</li>
          </ul>
          <!-- Urinalysis -->

          <!-- Physical Examination -->
          <h6 class="title" style="color: #021B29;background-color: rgba(7, 121, 184, .1);font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;padding: .7rem 0;margin-bottom: 1rem;text-align: center">Physical Examination</h6>
          <ul class="personal_details" style="display: flex; flex-wrap: wrap;border: 1px solid #576065;list-style: none;">
            <li class="list" style="width: 40%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;"></li>
            <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">Normal</li>
            <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">Abnormal</li>
            <li class="list" style="width: 20%;padding: 0.5rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065">Comment</li>
            <!-- EYES / PUPILS -->
            <li class="list" style="width: 40%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">1. EYES / PUPILS</li>
            <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.EYES_PUPILS == 1"></i></li>
            <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.EYES_PUPILS == 0"></i></li>
            <li class="list" style="width: 20%;padding: 0.5rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Almarai', sans-serif; font-size: 0.8rem; border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center">{{track.EYES_PUPILS_comment}}</li>
            <!-- EYES / PUPILS -->

            <!-- EAR, NOSE & THROAT -->
            <li class="list" style="width: 40%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">2. EAR, NOSE & THROAT</li>
            <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.EAR_NOSE_THROAT == 1"></i></li>
            <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.EAR_NOSE_THROAT == 0"></i></li>
            <li class="list" style="width: 20%;padding: 0.5rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Almarai', sans-serif; font-size: 0.8rem; border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center">{{track.EAR_NOSE_THROAT_comment}}</li>
            <!-- EAR, NOSE & THROAT -->

            <!-- TEETH -->
            <li class="list" style="width: 40%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">3. TEETH</li>
            <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.TEETH == 1"></i></li>
            <li class="list" style="width: 20%;padding: 0.36rem 1rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.TEETH == 0"></i></li>
            <li class="list" style="width: 20%;padding: 0.5rem ;border-bottom: 1px solid #576065;font-size: 0.9rem;font-family: 'Almarai', sans-serif; font-size: 0.8rem; border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center">{{track.TEETH_comment}}</li>
            <!-- TEETH -->

            <!-- LUNGS / CHEST -->
            <li class="list" style="width: 40%;padding: 0.36rem 1rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">4. LUNGS / CHEST</li>
            <li class="list" style="width: 20%;padding: 0.36rem 1rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.LUNGS_CHEST == 1"></i></li>
            <li class="list" style="width: 20%;padding: 0.36rem 1rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065"><i class="fa-solid fa-check" v-if="track.LUNGS_CHEST == 0"></i></li>
            <li class="list" style="width: 20%;padding: 0.5rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Almarai', sans-serif; font-size: 0.8rem; border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center">{{track.LUNGS_CHEST_comment}}</li>
            <!-- LUNGS / CHEST -->

            <!-- CARDIOVASCULAR -->
            <li class="list" style="width: 40%;padding: 0.36rem 1rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">5. CARDIOVASCULAR</li>
            <li class="list" style="width: 20%;padding: 0.36rem 1rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.CARDIOVASCULAR == 1"></i></li>
            <li class="list" style="width: 20%;padding: 0.36rem 1rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065"><i class="fa-solid fa-check" v-if="track.CARDIOVASCULAR == 0"></i></li>
            <li class="list" style="width: 20%;padding: 0.5rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Almarai', sans-serif; font-size: 0.8rem; border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center">{{track.CARDIOVASCULAR_comment}}</li>
            <!-- CARDIOVASCULAR -->

            <!-- ABDOMEN -->
            <li class="list" style="width: 40%;padding: 0.36rem 1rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">6. ABDOMEN</li>
            <li class="list" style="width: 20%;padding: 0.36rem 1rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.ABDOMEN == 1"></i></li>
            <li class="list" style="width: 20%;padding: 0.36rem 1rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.ABDOMEN == 0"></i></li>
            <li class="list" style="width: 20%;padding: 0.5rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Almarai', sans-serif; font-size: 0.8rem; border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center">{{track.ABDOMEN_comment}}</li>
            <!-- ABDOMEN -->

            <!-- HERNIAL ORIFICES -->
            <li class="list" style="width: 40%;padding: 0.36rem 1rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">7. HERNIAL ORIFICES</li>
            <li class="list" style="width: 20%;padding: 0.36rem 1rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.HERNIAL_ORIFICES == 1"></i></li>
            <li class="list" style="width: 20%;padding: 0.36rem 1rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.HERNIAL_ORIFICES == 0"></i></li>
            <li class="list" style="width: 20%;padding: 0.5rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Almarai', sans-serif; font-size: 0.8rem; border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center">{{track.HERNIAL_ORIFICES_comment}}</li>
            <!-- HERNIAL ORIFICES -->

            <!-- GENITOURINARY -->
            <li class="list" style="width: 40%;padding: 0.36rem 1rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">8. GENITOURINARY</li>
            <li class="list" style="width: 20%;padding: 0.36rem 1rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.GENITOURINARY == 1"></i></li>
            <li class="list" style="width: 20%;padding: 0.36rem 1rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.GENITOURINARY == 0"></i></li>
            <li class="list" style="width: 20%;padding: 0.5rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Almarai', sans-serif; font-size: 0.8rem; border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center">{{track.GENITOURINARY_comment}}</li>
            <!-- GENITOURINARY -->

            <!-- MUSCULOSKELETAL -->
            <li class="list" style="width: 40%;padding: 0.36rem 1rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">9. MUSCULOSKELETAL</li>
            <li class="list" style="width: 20%;padding: 0.36rem 1rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.MUSCULOSKELETAL == 1"></i></li>
            <li class="list" style="width: 20%;padding: 0.36rem 1rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.MUSCULOSKELETAL == 0"></i></li>
            <li class="list" style="width: 20%;padding: 0.5rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Almarai', sans-serif; font-size: 0.8rem; border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center">{{track.MUSCULOSKELETAL_comment}}</li>
            <!-- MUSCULOSKELETAL -->

            <!-- SKIN -->
            <li class="list" style="width: 40%;padding: 0.36rem 1rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">10. SKIN</li>
            <li class="list" style="width: 20%;padding: 0.36rem 1rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.SKIN == 1"></i></li>
            <li class="list" style="width: 20%;padding: 0.36rem 1rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.SKIN == 0"></i></li>
            <li class="list" style="width: 20%;padding: 0.5rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Almarai', sans-serif; font-size: 0.8rem; border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center">{{track.SKIN_comment}}</li>
            <!-- SKIN -->

            <!-- VARICOSE VEINS -->
            <li class="list" style="width: 40%;padding: 0.36rem 1rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">11. VARICOSE VEINS</li>
            <li class="list" style="width: 20%;padding: 0.36rem 1rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.VARICOSE_VEINS == 1"></i></li>
            <li class="list" style="width: 20%;padding: 0.36rem 1rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.VARICOSE_VEINS == 0"></i></li>
            <li class="list" style="width: 20%;padding: 0.5rem; border-bottom: 1px solid #576065; font-size: 0.9rem;font-family: 'Almarai', sans-serif; font-size: 0.8rem; border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center">{{track.VARICOSE_VEINS_comment}}</li>
            <!-- VARICOSE VEINS -->

            <!-- NEUROLOGICAL -->
            <li class="list" style="width: 40%;padding: 0.36rem 1rem ;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;">12. NEUROLOGICAL</li>
            <li class="list" style="width: 20%;padding: 0.36rem 1rem ;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.NEUROLOGICAL == 1"></i></li>
            <li class="list" style="width: 20%;padding: 0.36rem 1rem ;font-size: 0.9rem;font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center"><i class="fa-solid fa-check" v-if="track.NEUROLOGICAL == 0"></i></li>
            <li class="list" style="width: 20%;padding: 0.5rem ;font-size: 0.9rem;font-family: 'Almarai', sans-serif; font-size: 0.8rem; border-inline-start: 1px solid #576065; display: flex; align-items: center; justify-content: center">{{track.NEUROLOGICAL_comment}}</li>
            <!-- NEUROLOGICAL -->
          </ul>
          <!-- Physical Examination -->
        </div>
      </div>

      <div class="a4" style="min-height: 29.7cm">
        <div class="certifcate_pdf" v-if="track.fit == 1" style="position: relative;border: 1px solid #212529;padding: 0 1rem">
          <!-- Image -->
          <div class="images" v-for="(image, index) in track.base_image" :key="index" style="position:relative">
            <img :src="image" v-if="image != null" :alt="index" class="a4_image" style="width: 100%; height: 29.7cm;">
            <div class="image" v-if="image != null" style="position: absolute;width: fit-content;margin: auto;bottom: 0.5cm;left: 0.5cm;">
              <img
                :src="qrCodeImage"
                class="qr"
                alt="QR Code"
                style="display: block; margin: 0 auto;position: absolute; top: 40px; left: 50px;height: 70px"
              />
              <img
                class="signature"
                style="width: 170px;opacity: 0.7"
                src="../assets/media/image/signature.png"
              />
            </div>
          </div>
        </div>
        <!-- Image -->
      </div>
    </div>
    <button class="btn PrimaryButton" @click="downloadPDF">Download as pdf</button>
  </div>
</template>
<script>
import axios from "axios";
import { ref, onMounted } from 'vue';
import html2pdf from "html2pdf.js";
import QRCode from "qrcode";
import { useRoute } from 'vue-router';

export default {
  name: "certificate-pdf",
  data() {
    return {
      setting: {},
      track: {},
      logo: require("../assets/media/image/logo_certifcate.png"),
      oeuk: require("../assets/media/image/oeuk.png"),
      signature: require("../assets/media/image/signature.png"),
      elite: require("../assets/media/image/elite.png"),
      base64Images: [],
      imageUrls: [],
    };
  },
  setup() {
    const qrCodeImage = ref('');

    onMounted(() => {
      const route = useRoute();
      const routeId = route.params.id;
      const qrCodeValue = `https://pmgoccuhealth.com/fitness_certificate/${routeId}`;

      QRCode.toDataURL(qrCodeValue)
        .then((url) => {
          qrCodeImage.value = url;
        })
        .catch((error) => {
          console.error('Error generating QR code:', error);
        });
    });

    return {
      qrCodeImage,
    };
  },
  methods: {
    downloadPDF() {
      const element = document.getElementById("downloadCertificate"); // Replace 'myDiv' with the ID of your div element
      const option = {
        margin: 0,
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        preserveCSS: true,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 }, // Increase scale to improve text quality
      }
      html2pdf()
        .from(element)
        .set(option)
        .save("Certificate.pdf");
    },
    fetch_settings() {
      axios.get("/fetch_settings").then(({ data }) => {
        this.setting = data.data;
        // console.log(this.setting);
      });
    },

    search_serial_number() {
      axios
        .get(`/search_serial_number/${this.$route.params.id}`)
        .then(({ data }) => {
          this.status = data.status;
          this.track = data.data;
          this.imageUrls = data.data.images;
          // this.base64Images = [];
          // for (const url of this.imageUrls) {
          //   const response =  axios.get(url, { responseType: 'arraybuffer' });
          //   const base64Image = this.arrayBufferToBase64(response.data);
          //   this.base64Images.push(base64Image);
          //   console.log(this.base64Images)
          // }
        })
        .catch((error) => {
          console.log(error);
          return error;
        })
        .finally(() => {
          //Perform action in always
        });
    },

    arrayBufferToBase64(buffer) {
      let binary = '';
      const bytes = new Uint8Array(buffer);
      for (let i = 0; i < bytes.length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    },
  },
  created() {
    this.fetch_settings();
    this.search_serial_number();
  },
};
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap');
  .download_certificate_pdf {
    position: relative;
    margin: 5rem auto;
    /* border: 1px solid #212529; */
    /* padding: 2.5rem; */
  }
  .download_pdf {
    width: 100%;
  }
  .download_pdf .certifcate_pdf {
    width: 100%;
    margin: 0 auto;
  }
  .download_certificate_pdf .PrimaryButton{
    display: block;
    margin-inline-start: auto;
    /* margin-inline-end: 5%; */
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }
  .before {
    background-image: url('../assets/media/image/elite.png');
  }
  @media only screen and (max-width: 991.98px) {
    .download_certificate_pdf {
      width: 95%;
    }
  }
  @media only screen and (max-width: 600px) {
    .download_certificate_pdf * {
      font-size: .8rem;
    }
    .download_pdf {
      width: 95%;
    }
    .download_certificate_pdf .title {
      font-size: 1.5rem !important;
    }
    .download_certificate_pdf .text {
      font-size: 1rem !important;
    }
    .download_certificate_pdf {
      width: 100%;
      padding: 0;
    }
    .qr {
      width: 120px !important;
      height: 120px !important;
    }
    .logo {
      height: 50px !important;
      width: 90px !important;
    }
    .oeuk {
      height: 80px !important;
      width: 120px !important;
    }
    .download_certificate_pdf .PrimaryButton {
      margin-inline-end: 1rem;
      margin-bottom: 1rem;
    }
    .download_certificate_pdf .contact {
      flex-direction: column;
      align-items: start !important;
    }
  }
  .a4 .certifcate_pdf .images:last-child .a4_image{
    height: 29cm !important;
  }
</style>
