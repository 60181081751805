<template>
  <lottie-player src="https://lottie.host/f2079f79-e083-444d-9eb6-e3f55c9c86c5/8GF53n3qT8.json" style="width: 600px; height: 600px" speed="1" loop autoplay direction="1" mode="normal"></lottie-player>
</template>

<script>
export default {
    name: "lottie-loading"
}
</script>

<style>

</style>