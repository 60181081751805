<template>
    <section class="about" id="about">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                    <h2 class="title">{{about.title}}</h2>
                    <p class="text" v-html="about.sub_title"></p>
                    <ul class="features">
                        <li class="feature" v-for="(feature, index) in about.features" :key="index">
                            <img :src="feature.image" class="feature_image" :alt="feature.title">
                            <div class="info">
                                <h4 class="title">{{feature.title}}</h4>
                                <p class="text">{{feature.text}}</p>
                            </div>
                        </li>
                    </ul>
                    <a href="https://oeuk.org.uk/doctors/find-a-registered-doctor/overseas-doctors" target="_blank" class="btn PrimaryButton">{{$t("Check_Our_Certificate")}}</a>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <img :src="about.image" class="about_image" :alt="about.title">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';

export default {
    name: "about-home",
    data() {
        return {
            about: {}
        }
    },
    methods: {
        fetch_about_us() {
            axios.get("/fetch_about_us").then(({ data }) => {
                this.about = data.data;
                // console.log(this.about);
            });
        },
    },
    created() {
        this.fetch_about_us()
    }
}
</script>

<style>

</style>