<template>
<section class="terms">
    <div class="container">
        <h1 class="title">Terms & Conditions</h1>
        <p class="text" v-html="terms"></p>
    </div>
</section>
</template>

<script>
import axios from 'axios';

export default {
    name: "terms-page",
    data() {
        return {
            terms: {}
        }
    },
    methods: {
        fetch_term() {
            axios.get("/fetch_term").then(({ data }) => {
                this.terms = data.data;
                // console.log(this.terms);
            });
        },
    },
    created() {
        this.fetch_term();
    }
}
</script>

<style>

</style>