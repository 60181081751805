<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <form-wizard
    @on-complete="SubmitFitnessForm"
    shape="tab"
    back-button-text="Back"
    next-button-text="Save & Next"
    finish-button-text="Submit"
    color="#0779b8"
    class="form"
  >
    <tab-content title="General Information" :before-change="FirstStep">
      <h4 class="section_title">General Information</h4>
      <div class="row">
        <div class="form-group">
          <label for="Passport">Passport / ID *</label>
          <input
            type="text"
            name=""
            id="Passport"
            class="form-control"
            v-model="Form.passport_id"
            placeholder="Passport / ID *"
          />
        </div>
        <div class="form-group">
          <label for="Passport">Passport / ID Image *</label>
          <div class="file-upload-form">
            <label
              for="files"
              class="form-control d-flex align-items-center justify-content-center"
            >
              <i class="fa-solid fa-upload"></i>
              <div class="texts">
                <p class="title mb-2">Press To Add image</p>
                {{
                  imageName == ""
                    ? "Please upload Passport / ID Image"
                    : imageName
                }}
              </div>
            </label>
            <input
              type="file"
              ref="file"
              id="files"
              hidden
              @change="previewImagePassport"
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
          </div>
          <!-- Button trigger modal -->
          <button
            type="button"
            v-if="passport_image_preview"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#passportModal"
          >
            Preview Passport / ID Image
          </button>

          <!-- Modal -->
          <div
            class="modal fade passportModal"
            id="passportModal"
            tabindex="-1"
            aria-labelledby="passportModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body">
                  <div class="image-preview">
                    <img class="preview" :src="passport_image_preview" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="name">Full Name *</label>
          <input
            type="text"
            name=""
            id="name"
            class="form-control"
            v-model="Form.full_name"
            placeholder="Full Name *"
          />
        </div>
        <div class="form-group">
          <label for="mobile">Mobile Number *</label>
          <input
            type="text"
            name=""
            id="mobile"
            class="form-control"
            v-model="Form.phone"
            placeholder="Mobile Number *"
          />
          <!-- @input="validatePhoneNumber" -->
          <p v-if="validationError" class="error-message text-danger">
            {{ validationError }}
          </p>
        </div>
        <div class="form-group">
          <label for="email">E-mail Address *</label>
          <input
            type="email"
            name=""
            id="email"
            class="form-control"
            v-model="Form.email"
            placeholder="E-mail Address *"
          />
        </div>
        <div class="form-group">
          <label for="dateOfBirth">Date of Birth *</label>
          <datepicker
            id="dateOfBirth"
            :icon-color="color"
            :disabled-dates="{ to: new Date(1930, 10, 5), from: new Date() }"
            v-model="date_of_birth"
            placeholder="Date of Birth *"
          ></datepicker>
        </div>
        <div class="form-group">
          <label for="job">Previous Job Title *</label>
          <input
            type="text"
            name=""
            id="job"
            class="form-control"
            v-model="Form.job_title"
            placeholder="enter your job title"
          />
        </div>
        <div class="form-group">
          <label for="address">Address *</label>
          <input
            type="text"
            name=""
            id="address"
            class="form-control"
            v-model="Form.address"
            placeholder="enter address"
          />
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group w-100">
            <label for="" class="d-block">Payment by company or personal</label>
            <div class="form-group type">
              <input
                type="radio"
                name="type"
                value="0"
                v-model="Form.type"
                id="personalRadio"
                hidden
              />
              <label for="personalRadio">Personal</label>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group w-100">
            <label for="" class="d-block invisible"
              >Company / Personal ? *</label
            >
            <div class="form-group type me-3">
              <input
                type="radio"
                name="type"
                value="1"
                v-model="Form.type"
                id="companyRadio"
                hidden
              />
              <label for="companyRadio">Company</label>
            </div>
          </div>
        </div>
        <div class="form-group" v-if="Form.type == 1">
          <label for="company">Company *</label>
          <input
            type="text"
            name=""
            id="company"
            class="form-control"
            v-model="Form.company"
            placeholder="enter company"
            required
          />
        </div>
        <div class="form-group">
          <label for="jop_applying_for">Job Applying for: *</label>
          <input
            type="text"
            name=""
            id="jop_applying_for"
            class="form-control"
            v-model="Form.jop_applying_for"
            placeholder="enter Job Applying for"
          />
        </div>
        <div class="form-group">
          <label for="workplace_setting">Workplace settings: *</label>
          <input
            type="text"
            name=""
            id="workplace_setting"
            class="form-control"
            v-model="Form.workplace_setting"
            placeholder="enter workplace setting"
          />
        </div>
        <div class="form-group">
          <label for="accommodations">accommodations: *</label>
          <input
            type="text"
            name=""
            id="accommodations"
            class="form-control"
            v-model="Form.accommodations"
            placeholder="enter accommodations"
          />
        </div>
        <div class="form-group">
          <label for="country_of_origin">country of origin: *</label>
          <input
            type="text"
            name=""
            id="country_of_origin"
            class="form-control"
            v-model="Form.country_of_origin"
            placeholder="enter country of origin"
          />
        </div>
        <div class="form-group">
          <label for="country_of_work">country of work: *</label>
          <input
            type="text"
            name=""
            id="country_of_work"
            class="form-control"
            v-model="Form.country_of_work"
            placeholder="enter country of work"
          />
        </div>
        <div class="form-group">
          <label for="valid_for_work_in">valid for work in: *</label>
          <input
            type="text"
            name=""
            id="valid_for_work_in"
            class="form-control"
            v-model="Form.valid_for_work_in"
            placeholder="enter valid for work in"
          />
        </div>
      </div>
    </tab-content>
    <tab-content title="Medical History" :before-change="SecondStep">
      <h4 class="section_title">Medical History</h4>
      <div class="form-group">
        <label for="date">Last Medical Exam *</label>
        <datepicker
          id="date"
          :icon-color="color"
          :disabled-dates="{ to: new Date(1930, 10, 5), from: new Date() }"
          v-model="last_medical_exam_date"
          placeholder="enter your medical exam"
        ></datepicker>
      </div>
      <div class="form-group-check">
        <label for="date"
          >DO YOU HAVE OR HAVE YOU BEEN DIAGNOSED AS SUFFERING FROM ANY OF THE
          FOLLOWING? *</label
        >
        <div
          class="form-check"
          v-for="(diagnose, index) in diagnosed_diseases"
          :key="index"
        >
          <input
            class="form-check-input"
            v-model="diagnosed_disease_ids"
            type="checkbox"
            :value="diagnose.id"
            :id="`diagnose${diagnose.id}`"
          />
          <label class="form-check-label" :for="`diagnose${diagnose.id}`">
            {{ diagnose.title }}
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="diagnosed_diseases_other"
            type="checkbox"
            value="0"
            id="diagnosed_disease_other0"
          />
          <label class="form-check-label" for="diagnosed_disease_other0">
            Other
          </label>
        </div>
      </div>
      <div class="form-group" v-if="diagnosed_diseases_other">
        <label for="">Other diagnosed diseases</label>
        <input
          type="text"
          name=""
          id=""
          class="form-control"
          v-model="diagnosed_disease_texts"
          placeholder="Please enter other diagnosed diseases"
        />
      </div>
      <!-- family_have_history_of_above_conditions -->
      <div class="form-group-check">
        <label for="date"
          >DO ANY OF YOUR IMMEDIATE FAMILY (PARENTS/BROTHERS/SISTERS) HAVE A
          HISTORY OF ANY OF THE ABOVE CONDITIONS? *</label
        >
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="Form.family_have_history_of_above_conditions"
            type="radio"
            value="1"
            id="aboveConditionsYes"
          />
          <label class="form-check-label" for="aboveConditionsYes"> Yes </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="Form.family_have_history_of_above_conditions"
            type="radio"
            value="0"
            id="aboveConditionsNo"
          />
          <label class="form-check-label" for="aboveConditionsNo"> No </label>
        </div>
      </div>
      <div
        class="form-group mt-2"
        v-if="Form.family_have_history_of_above_conditions == 1"
      >
        <label for="do_you_smoke_text">Enter *</label>
        <input
          type="text"
          name=""
          id="do_you_smoke_text"
          class="form-control"
          v-model="family_have_history_of_above_conditions_text"
          placeholder="enter"
        />
      </div>
      <!-- family_have_history_of_above_conditions -->

      <div class="form-group-check">
        <label for="date">Do you currently have any of the following? *</label>
        <div
          class="form-check"
          v-for="(current_disease, index) in current_diseases"
          :key="index"
        >
          <input
            class="form-check-input"
            v-model="current_disease_ids"
            type="checkbox"
            :value="current_disease.id"
            :id="`current_disease${current_disease.id}`"
          />
          <label
            class="form-check-label"
            :for="`current_disease${current_disease.id}`"
          >
            {{ current_disease.title }}
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="current_disease_other"
            type="checkbox"
            value="0"
            id="current_disease0"
          />
          <label class="form-check-label" for="current_disease0"> Other </label>
        </div>
      </div>
      <div class="form-group" v-if="current_disease_other">
        <label for="">Other diagnosed diseases</label>
        <input
          type="text"
          name=""
          id=""
          class="form-control"
          v-model="current_disease_texts"
          placeholder="Please enter other diagnosed diseases"
        />
      </div>
    </tab-content>
    <tab-content
      title="SOCIAL / OCCUPATIONAL HISTORY"
      :before-change="ThirdStep"
    >
      <h4 class="section_title">SOCIAL / OCCUPATIONAL HISTORY</h4>
      <!-- Do you smoke? -->
      <div class="form-group-check">
        <label for="date">Do you smoke? *</label>
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="Form.do_you_smoke"
            type="radio"
            value="1"
            id="doYouSmokeYes"
          />
          <label class="form-check-label" for="doYouSmokeYes"> Yes </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="Form.do_you_smoke"
            type="radio"
            value="0"
            id="doYouSmokeNo"
          />
          <label class="form-check-label" for="doYouSmokeNo"> No </label>
        </div>
      </div>
      <div class="form-group mt-2" v-if="Form.do_you_smoke == 1">
        <label for="do_you_smoke_text">Enter *</label>
        <input
          type="text"
          name=""
          id="do_you_smoke_text"
          class="form-control"
          v-model="do_you_smoke_text"
          placeholder="enter"
        />
      </div>
      <!-- Do you smoke? -->

      <!-- if an ex smoker text -->
      <div class="form-group-check">
        <label for="date">If an ex-smoker, ? *</label>
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="Form.if_an_ex_smoker"
            type="radio"
            value="1"
            id="ifAnExSmokerYes"
          />
          <label class="form-check-label" for="ifAnExSmokerYes"> Yes </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="Form.if_an_ex_smoker"
            type="radio"
            value="0"
            id="ifAnExSmokerNo"
          />
          <label class="form-check-label" for="ifAnExSmokerNo"> No </label>
        </div>
      </div>
      <div class="form-group mt-2" v-if="Form.if_an_ex_smoker == 1">
        <label for="if_an_ex_smoker_text">Enter *</label>
        <input
          type="text"
          name=""
          id="if_an_ex_smoker_text"
          class="form-control"
          v-model="if_an_ex_smoker_text"
          placeholder="enter"
        />
      </div>
      <!-- if an ex smoker text -->

      <!-- average_alcohol_consumption_text -->
      <div class="form-group-check">
        <label for="date">Average alcohol consumption *</label>
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="Form.average_alcohol_consumption"
            type="radio"
            value="1"
            id="averageAlcoholConsumptionYes"
          />
          <label class="form-check-label" for="averageAlcoholConsumptionYes">
            Yes
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="Form.average_alcohol_consumption"
            type="radio"
            value="0"
            id="averageAlcoholConsumptionNo"
          />
          <label class="form-check-label" for="averageAlcoholConsumptionNo">
            No
          </label>
        </div>
      </div>
      <div class="form-group mt-2" v-if="Form.average_alcohol_consumption == 1">
        <label for="average_alcohol_consumption_text">Enter *</label>
        <input
          type="text"
          name=""
          id="average_alcohol_consumption_text"
          class="form-control"
          v-model="average_alcohol_consumption_text"
          placeholder="enter"
        />
      </div>
      <!-- average_alcohol_consumption_text -->

      <!-- occupational_hazard_text -->
      <div class="form-group-check">
        <label for="date"
          >Have you ever been exposed to any known occupational hazard such as
          noise, radiation, dusts, asbestos, chemicals orlead? *</label
        >
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="Form.occupational_hazard"
            type="radio"
            value="1"
            id="occupationalHazardYes"
          />
          <label class="form-check-label" for="occupationalHazardYes">
            Yes
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="Form.occupational_hazard"
            type="radio"
            value="0"
            id="occupationalHazardNo"
          />
          <label class="form-check-label" for="occupationalHazardNo">
            No
          </label>
        </div>
      </div>
      <div class="form-group mt-2" v-if="Form.occupational_hazard == 1">
        <label for="occupational_hazard_text">Enter *</label>
        <input
          type="text"
          name=""
          id="occupational_hazard_text"
          class="form-control"
          v-model="occupational_hazard_text"
          placeholder="enter"
        />
      </div>
      <!-- occupational_hazard_text -->

      <!-- protective_clothing_text -->
      <div class="form-group-check">
        <label for="date"
          >Do you use protective clothing, safety glasses or hearing protection?
          *</label
        >
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="Form.protective_clothing"
            type="radio"
            value="1"
            id="protectiveClothingYes"
          />
          <label class="form-check-label" for="protectiveClothingYes">
            Yes
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="Form.protective_clothing"
            type="radio"
            value="0"
            id="protectiveClothingNo"
          />
          <label class="form-check-label" for="protectiveClothingNo">
            No
          </label>
        </div>
      </div>
      <div class="form-group mt-2" v-if="Form.protective_clothing == 1">
        <label for="protective_clothing_text">Enter *</label>
        <input
          type="text"
          name=""
          id="protective_clothing_text"
          class="form-control"
          v-model="protective_clothing_text"
          placeholder="enter"
        />
      </div>
      <!-- protective_clothing_text -->

      <!-- developed_medical_condition_text -->
      <div class="form-group-check">
        <label for="date"
          >Have you ever developed any medical condition in connection with your
          occupation? e.g. hearing loss / skin condition/wheeze/backache/muscle
          strain/blood disease? *</label
        >
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="Form.developed_medical_condition"
            type="radio"
            value="1"
            id="developedMedicalConditionYes"
          />
          <label class="form-check-label" for="developedMedicalConditionYes">
            Yes
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="Form.developed_medical_condition"
            type="radio"
            value="0"
            id="developedMedicalConditionNo"
          />
          <label class="form-check-label" for="developedMedicalConditionNo">
            No
          </label>
        </div>
      </div>
      <div class="form-group mt-2" v-if="Form.developed_medical_condition == 1">
        <label for="developed_medical_condition_text">Enter *</label>
        <input
          type="text"
          name=""
          id="developed_medical_condition_text"
          class="form-control"
          v-model="developed_medical_condition_text"
          placeholder="enter"
        />
      </div>
      <!-- developed_medical_condition_text -->

      <!-- industrial_injury_text -->
      <div class="form-group-check">
        <label for="date"
          >Have you ever suffered any industrial injury? *</label
        >
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="Form.industrial_injury"
            type="radio"
            value="1"
            id="industrialInjuryYes"
          />
          <label class="form-check-label" for="industrialInjuryYes">
            Yes
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="Form.industrial_injury"
            type="radio"
            value="0"
            id="industrialInjuryNo"
          />
          <label class="form-check-label" for="industrialInjuryNo"> No </label>
        </div>
      </div>
      <div class="form-group mt-2" v-if="Form.industrial_injury == 1">
        <label for="industrial_injury_text">Enter *</label>
        <input
          type="text"
          name=""
          id="industrial_injury_text"
          class="form-control"
          v-model="industrial_injury_text"
          placeholder="enter"
        />
      </div>
      <!-- industrial_injury_text -->

      <!-- udiometric_screening_text -->
      <div class="form-group-check">
        <label for="date"
          >Have you ever had any previous audiometric screening? *</label
        >
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="Form.udiometric_screening"
            type="radio"
            value="1"
            id="udiometricScreeningYes"
          />
          <label class="form-check-label" for="udiometricScreeningYes">
            Yes
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="Form.udiometric_screening"
            type="radio"
            value="0"
            id="udiometricScreeningNo"
          />
          <label class="form-check-label" for="udiometricScreeningNo">
            No
          </label>
        </div>
      </div>
      <div class="form-group mt-2" v-if="Form.udiometric_screening == 1">
        <label for="udiometric_screening_text">Enter *</label>
        <input
          type="text"
          name=""
          id="udiometric_screening_text"
          class="form-control"
          v-model="udiometric_screening_text"
          placeholder="enter"
        />
      </div>
      <!-- udiometric_screening_text -->

      <!-- lung_function_screening_text -->
      <div class="form-group-check">
        <label for="date"
          >Have you ever had previous lung function screening? *</label
        >
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="Form.lung_function_screening"
            type="radio"
            value="1"
            id="lungFunctionScreeningYes"
          />
          <label class="form-check-label" for="lungFunctionScreeningYes">
            Yes
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="Form.lung_function_screening"
            type="radio"
            value="0"
            id="lungFunctionScreeningNo"
          />
          <label class="form-check-label" for="lungFunctionScreeningNo">
            No
          </label>
        </div>
      </div>
      <div class="form-group mt-2" v-if="Form.lung_function_screening == 1">
        <label for="lung_function_screening_text">Enter *</label>
        <input
          type="text"
          name=""
          id="lung_function_screening_text"
          class="form-control"
          v-model="lung_function_screening_text"
          placeholder="enter"
        />
      </div>
      <!-- lung_function_screening_text -->

      <!-- rejected_employment_medical_grounds_text -->
      <div class="form-group-check">
        <label for="date"
          >Have you ever been rejected from employment on medical grounds?
          *</label
        >
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="Form.rejected_employment_medical_grounds"
            type="radio"
            value="1"
            id="rejectedEmploymentMedicalGroundsYes"
          />
          <label
            class="form-check-label"
            for="rejectedEmploymentMedicalGroundsYes"
          >
            Yes
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="Form.rejected_employment_medical_grounds"
            type="radio"
            value="0"
            id="rejectedEmploymentMedicalGroundsNo"
          />
          <label
            class="form-check-label"
            for="rejectedEmploymentMedicalGroundsNo"
          >
            No
          </label>
        </div>
      </div>
      <div
        class="form-group mt-2"
        v-if="Form.rejected_employment_medical_grounds == 1"
      >
        <label for="rejected_employment_medical_grounds_text">Enter *</label>
        <input
          type="text"
          name=""
          id="rejected_employment_medical_grounds_text"
          class="form-control"
          v-model="rejected_employment_medical_grounds_text"
          placeholder="enter"
        />
      </div>
      <!-- rejected_employment_medical_grounds_text -->

      <!-- received_compensation_text -->
      <div class="form-group-check">
        <label for="date"
          >Have you ever received compensation, or is there any industrial claim
          pending? *</label
        >
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="Form.received_compensation"
            type="radio"
            value="1"
            id="receivedCompensationYes"
          />
          <label class="form-check-label" for="receivedCompensationYes">
            Yes
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="Form.received_compensation"
            type="radio"
            value="0"
            id="receivedCompensationNo"
          />
          <label class="form-check-label" for="receivedCompensationNo">
            No
          </label>
        </div>
      </div>
      <div class="form-group mt-2" v-if="Form.received_compensation == 1">
        <label for="received_compensation_text">Enter *</label>
        <input
          type="text"
          name=""
          id="received_compensation_text"
          class="form-control"
          v-model="received_compensation_text"
          placeholder="enter"
        />
      </div>
      <!-- received_compensation_text -->

      <!-- offshore_installation_text -->
      <div class="form-group-check">
        <label for="date"
          >Have you ever been medivaced from an offshore installation? *</label
        >
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="Form.offshore_installation"
            type="radio"
            value="1"
            id="offshoreInstallationYes"
          />
          <label class="form-check-label" for="offshoreInstallationYes">
            Yes
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="Form.offshore_installation"
            type="radio"
            value="0"
            id="offshoreInstallationNo"
          />
          <label class="form-check-label" for="offshoreInstallationNo">
            No
          </label>
        </div>
      </div>
      <div class="form-group mt-2" v-if="Form.offshore_installation == 1">
        <label for="offshore_installation_text">Enter *</label>
        <input
          type="text"
          name=""
          id="offshore_installation_text"
          class="form-control"
          v-model="offshore_installation_text"
          placeholder="enter"
        />
      </div>
      <!-- offshore_installation_text -->

      <div class="form-check form-check-declare">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="accept"
          true-value="1"
          false-value="0"
          id="declare"
        />
        <label class="form-check-label w-100" for="declare">
          I declare that all information above is correct.
        </label>
      </div>
    </tab-content>
  </form-wizard>
</template>

<script>
import Datepicker from "vuejs3-datepicker";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import Loading from "@/components/include/LottieFiles/loading.vue";
import { FormWizard, TabContent } from "vue3-form-wizard";
import "vue3-form-wizard/dist/style.css";

export default {
  data() {
    return {
      symptoms: [],
      diseases: [],
      Form: {
        certificate_type: 2,
        passport_id: "",
        passport_image: "",
        full_name: "",
        phone: "",
        email: "",
        job_title: "",
        address: "",
        company: "",
        type: "",
        eye_use: "",
        has_previous_operations: "",
        example_operations: "",
        jop_applying_for: "",
        workplace_setting: "",
        accommodations: "",
        country_of_origin: "",
        country_of_work: "",
        valid_for_work_in: "",
        family_have_history_of_above_conditions: "",
        do_you_smoke: "",
        if_an_ex_smoker: "",
        average_alcohol_consumption: "",
        occupational_hazard: "",
        protective_clothing: "",
        developed_medical_condition: "",
        industrial_injury: "",
        udiometric_screening: "",
        lung_function_screening: "",
        rejected_employment_medical_grounds: "",
        received_compensation: "",
        offshore_installation: "",
      },
      family_have_history_of_above_conditions_text: "",
      do_you_smoke_text: "",
      if_an_ex_smoker_text: "",
      average_alcohol_consumption_text: "",
      occupational_hazard_text: "",
      protective_clothing_text: "",
      developed_medical_condition_text: "",
      industrial_injury_text: "",
      udiometric_screening_text: "",
      lung_function_screening_text: "",
      rejected_employment_medical_grounds_text: "",
      received_compensation_text: "",
      offshore_installation_text: "",
      loading: false,
      symptom_ids: [],
      symptom_texts: [],
      diagnosed_diseases: [],
      current_diseases: [],
      chronic_disease_ids: [],
      chronic_disease_texts: [],
      diagnosed_disease_ids: [],
      diagnosed_disease_texts: [],
      current_disease_ids: [],
      current_disease_texts: [],
      date_of_birth: "",
      last_medical_exam_date: "",
      accept: "",
      symptom_other: "",
      diagnosed_diseases_other: "",
      current_disease_other: "",
      diagnose_ids: [],
      other_chronic_disease: "",
      passport_image_preview: "",
      imageName: "",
      validationError: "",
    };
  },
  components: {
    Datepicker,
    Loading,
    FormWizard,
    TabContent,
  },
  setup() {
    const color = "#0779b8";
    const height = "18";
    const width = "18";

    return {
      color,
      width,
      height,
    };
  },
  methods: {
    previewImagePassport(event) {
      const file = event.target.files[0];
      this.imageName = file.name;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.Form.passport_image = file;
        this.passport_image_preview = reader.result;
        // console.log(this.Form.passport_image)
      };
    },
    // image

    fetch_diagnosed_diseases() {
      axios.get("/fetch_diagnosed_diseases").then(({ data }) => {
        this.diagnosed_diseases = data.data;
        // console.log(this.hours);
      });
    },
    fetch_current_diseases() {
      axios.get("/fetch_current_diseases").then(({ data }) => {
        this.current_diseases = data.data;
        // console.log(this.hours);
      });
    },

    FirstStep() {
      if (this.Form.certificate_type == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select Certificate type",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.Form.passport_id == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please Enter Passport id",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.Form.full_name == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please Enter Full name",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.Form.phone == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please Enter phone number",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.Form.email == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please Enter email",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.passport_image_preview == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select Upload Passport / ID image",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.date_of_birth == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select Date of Birth",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.Form.job_title == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please Enter Job title",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.Form.address == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please Enter Address",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.Form.type == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please Enter Type",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.Form.jop_applying_for == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please Enter jop applying for",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.Form.workplace_setting == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please Enter workplace setting",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.Form.accommodations == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please Enter accommodations",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.Form.country_of_origin == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please Enter country of origin",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.Form.country_of_work == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please Enter country of work",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.Form.valid_for_work_in == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please Enter valid for work in",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else {
        return true;
      }
    },

    SecondStep() {
      if (this.last_medical_exam_date == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select Medical Exam Date",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (
        !this.diagnosed_disease_ids.length &&
        this.diagnosed_disease_texts == ""
      ) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select diagnosed disease",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.Form.family_have_history_of_above_conditions == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select family have history of above conditions",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (
        !this.current_disease_ids.length &&
        this.current_disease_texts == ""
      ) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select current disease",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else {
        return true;
      }
    },

    ThirdStep() {
      // Check choose yes or no
      if (this.Form.family_have_history_of_above_conditions == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select family have history of above conditions",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.Form.do_you_smoke == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select do you smoke",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.Form.if_an_ex_smoker == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select if an ex smoker",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.Form.average_alcohol_consumption == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select average alcohol consumption",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.Form.occupational_hazard == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select occupational hazard",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.Form.protective_clothing == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select protective clothing",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.Form.developed_medical_condition == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select developed medical condition",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.Form.industrial_injury == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select industrial injury",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.Form.udiometric_screening == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select udiometric screening",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.Form.lung_function_screening == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select lung function screening",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.Form.rejected_employment_medical_grounds == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select rejected employment medical grounds",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.Form.received_compensation == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select received compensation",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      } else if (this.Form.offshore_installation == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select offshore installation",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      }
      // Check choose yes or no

      // Check validation text when choose yes
      if (this.Form.family_have_history_of_above_conditions == 1) {
        if (this.family_have_history_of_above_conditions_text == "") {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Please text family have history of above conditions",
            showConfirmButton: true,
          });
          this.loading = false;
          return false;
        }
      } else if (this.Form.do_you_smoke == 1) {
        if (this.do_you_smoke_text == "") {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Please text do you smoke",
            showConfirmButton: true,
          });
          this.loading = false;
          return false;
        }
      } else if (this.Form.if_an_ex_smoker == 1) {
        if (this.if_an_ex_smoker_text == "") {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Please text if an ex smoker",
            showConfirmButton: true,
          });
          this.loading = false;
          return false;
        }
      } else if (this.Form.average_alcohol_consumption == 1) {
        if (this.average_alcohol_consumption_text == "") {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Please text average alcohol consumption",
            showConfirmButton: true,
          });
          this.loading = false;
          return false;
        }
      } else if (this.Form.occupational_hazard == 1) {
        if (this.occupational_hazard_text == "") {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Please text occupational hazard",
            showConfirmButton: true,
          });
          this.loading = false;
          return false;
        }
      } else if (this.Form.protective_clothing == 1) {
        if (this.protective_clothing_text == "") {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Please text protective clothing",
            showConfirmButton: true,
          });
          this.loading = false;
          return false;
        }
      } else if (this.Form.developed_medical_condition == 1) {
        if (this.developed_medical_condition_text == "") {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Please text developed medical condition",
            showConfirmButton: true,
          });
          this.loading = false;
          return false;
        }
      } else if (this.Form.industrial_injury == 1) {
        if (this.industrial_injury_text == "") {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Please text industrial injury",
            showConfirmButton: true,
          });
          this.loading = false;
          return false;
        }
      } else if (this.Form.udiometric_screening == 1) {
        if (this.udiometric_screening_text == "") {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Please text udiometric screening",
            showConfirmButton: true,
          });
          this.loading = false;
          return false;
        }
      } else if (this.Form.lung_function_screening == 1) {
        if (this.lung_function_screening_text == "") {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Please text lung function screening",
            showConfirmButton: true,
          });
          this.loading = false;
          return false;
        }
      } else if (this.Form.rejected_employment_medical_grounds == 1) {
        if (this.rejected_employment_medical_grounds_text == "") {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Please text rejected employment medical grounds",
            showConfirmButton: true,
          });
          this.loading = false;
          return false;
        }
      } else if (this.Form.received_compensation == 1) {
        if (this.received_compensation_text == "") {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Please text received compensation",
            showConfirmButton: true,
          });
          this.loading = false;
          return false;
        }
      } else if (this.Form.offshore_installation == 1) {
        if (this.offshore_installation_text == "") {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Please text offshore installation",
            showConfirmButton: true,
          });
          this.loading = false;
          return false;
        }
      } else if (this.accept == 0) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select you declare that all information",
          showConfirmButton: true,
        });
        this.loading = false;
        return false;
      }
      return true;
      // Check validation text when choose yes
    },

    SubmitFitnessForm() {
      this.loading = true; //the loading begin
      const formData = new FormData();
      Object.entries(this.Form).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append(
        "last_medical_exam_date",
        moment(this.last_medical_exam_date).format("YYYY/MM/DD")
      );
      formData.append(
        "date_of_birth",
        moment(this.date_of_birth).format("YYYY/MM/DD")
      );
      for (let index = 0; index < this.diagnosed_disease_ids.length; index++) {
        formData.append(
          `diagnosed_disease_ids[${index}]`,
          this.diagnosed_disease_ids[index]
        );
      }
      formData.append(
        `diagnosed_disease_texts[]`,
        this.diagnosed_disease_texts
      );
      for (let index = 0; index < this.current_disease_ids.length; index++) {
        formData.append(
          `current_disease_ids[${index}]`,
          this.current_disease_ids[index]
        );
      }
      formData.append(`current_disease_texts[]`, this.current_disease_texts);

      if (this.Form.family_have_history_of_above_conditions == 1) {
        formData.append(
          `family_have_history_of_above_conditions_text`,
          this.family_have_history_of_above_conditions_text
        );
      }
      if (this.Form.do_you_smoke == 1) {
        formData.append(`do_you_smoke_text`, this.do_you_smoke_text);
      }
      if (this.Form.if_an_ex_smoker == 1) {
        formData.append(`if_an_ex_smoker_text`, this.if_an_ex_smoker_text);
      }
      if (this.Form.average_alcohol_consumption == 1) {
        formData.append(
          `average_alcohol_consumption_text`,
          this.average_alcohol_consumption_text
        );
      }
      if (this.Form.occupational_hazard == 1) {
        formData.append(
          `occupational_hazard_text`,
          this.occupational_hazard_text
        );
      }
      if (this.Form.protective_clothing == 1) {
        formData.append(
          `protective_clothing_text`,
          this.protective_clothing_text
        );
      }
      if (this.Form.developed_medical_condition == 1) {
        formData.append(
          `developed_medical_condition_text`,
          this.developed_medical_condition_text
        );
      }
      if (this.Form.industrial_injury == 1) {
        formData.append(`industrial_injury_text`, this.industrial_injury_text);
      }
      if (this.Form.udiometric_screening == 1) {
        formData.append(
          `udiometric_screening_text`,
          this.udiometric_screening_text
        );
      }
      if (this.Form.lung_function_screening == 1) {
        formData.append(
          `lung_function_screening_text`,
          this.lung_function_screening_text
        );
      }
      if (this.Form.rejected_employment_medical_grounds == 1) {
        formData.append(
          `rejected_employment_medical_grounds_text`,
          this.rejected_employment_medical_grounds_text
        );
      }
      if (this.Form.received_compensation == 1) {
        formData.append(
          `received_compensation_text`,
          this.received_compensation_text
        );
      }
      if (this.Form.offshore_installation == 1) {
        formData.append(
          `offshore_installation_text`,
          this.offshore_installation_text
        );
      } else {
        axios
          .post("/send_request", formData)
          .then(({ data }) => {
            this.data = data;
            this.status = data.status;
            this.message = data.message;
            // console.log(data);
            if (this.status == true) {
              Swal.fire({
                title:
                  "Thanks For your Application, we will contact you soon with further details.",
                text: "Modal with a custom image.",
                imageUrl:
                  "https://cdn-icons-png.flaticon.com/512/5610/5610944.png",
                imageWidth: 110,
                imageHeight: 110,
                imageAlt: "Custom image",
                html:
                  `<p class="title mb-2">Serial No : ${this.data.data.serial_no}</p>` +
                  '<p class="text">Note: Examination will take 1 hour.</p>' +
                  '<div class="email"><p class="text">If you have any Questions, contact us on: </p><a href="mailto:info@pmgoccuhealth.com">info@pmgoccuhealth.com</a></div> ',
                showConfirmButton: false,
              });
              this.$router.push({ path: "/" });
            }
          })
          .catch((error) => {
            Swal.fire({
              type: "error",
              position: "center",
              icon: "error",
              title: error.response.data.message,
            });
            // console.log(error);
            // this.$router.push({ path: "/teachers" });
            this.loading = false;
          })
          .finally(() => (this.loading = false));
        {
          //Perform action in always
        }
      }
    },
  },
  created() {
    this.fetch_diagnosed_diseases();
    this.fetch_current_diseases();
  },
};
</script>

<style></style>
