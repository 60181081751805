<template>
  <section class="application">
    <div class="container">
      <div class="info">
        <h1 class="title">
          OEUK/OGUK Medical certificate and Fitness to work Examination
        </h1>
        <p class="text" v-if="certificate_type == 1">
          This is the standard offshore medical fitness assessment which is
          required for all offshore workers.
        </p>
        <p class="text" v-if="certificate_type == 2">
          Medically fit to fulfil the “Inherent requirements of the Job” (Capable of performing
          the tasks required & in the working
          conditions associated
          with the job)
        </p>
        <div class="row">
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group w-100">
              <label for="" class="d-block">Choose certificate type</label>
              <div class="form-group type">
                <input
                  type="radio"
                  name="certificate_type"
                  value="1"
                  v-model="certificate_type"
                  id="certificateMedical"
                  hidden
                />
                <label for="certificateMedical">OGUK/OEUk Medical Fitness Examination</label>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group w-100">
              <label for="" class="d-block invisible"
                >Choose certificate type</label
              >
              <div class="form-group type me-3">
                <input
                  type="radio"
                  name="certificate_type"
                  value="2"
                  v-model="certificate_type"
                  id="certificateFitness"
                  hidden
                />
                <label for="certificateFitness">Fitness to work Examination certificate</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <medicalFitness v-if="certificate_type == 1" />
      <fitnessWork v-if="certificate_type == 2" />
    </div>
  </section>
</template>

<script>
import medicalFitness from '@/components/include/application/medicalFitness.vue';
import fitnessWork from '@/components/include/application/fitnessWork.vue';

export default {
  name: "application-page",
  data() {
    return {
      certificate_type: "",
    };
  },
  components: {
    medicalFitness,
    fitnessWork,
  },
};
</script>

<style>
  .application::after {
    height: 80vh !important;
  }
</style>
