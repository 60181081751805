<template>
  <router-link :to="`blog/${blog.id}`" class="blog_card">
    <img :src="blog.image" :alt="blog.title" />
    <div class="content_blog">
        <p class="date">{{blog.date}}</p>
        <h5 class="title">{{blog.title}}</h5>
        <p class="text" v-html="blog.text"></p>
        <span class="read_more">Read More <i class="fa-regular fa-square-caret-right"></i></span>
    </div>
  </router-link>
</template>

<script>
export default {
    name: "blog-card",
    props: ["blog"]
};
</script>

<style></style>
