<template>
<section class="terms">
    <div class="container">
        <h1 class="title">privacy policy</h1>
        <p class="text" v-html="privacy"></p>
    </div>
</section>
</template>

<script>
import axios from 'axios';

export default {
    name: "privacy-page",
    data() {
        return {
            privacy: {}
        }
    },
    methods: {
        fetch_privacy() {
            axios.get("/fetch_privacy").then(({ data }) => {
                this.privacy = data.data;
                // console.log(this.hours);
            });
        },
    },
    created() {
        this.fetch_privacy();
    }
}
</script>

<style>

</style>