<template>
    <section class="blog_details">
        <div class="container">
            <img :src="blog.image" :alt="blog.title">
            <h1 class="title">{{blog.title}}</h1>
            <p class="text" v-html="blog.text"></p>
        </div>
    </section>
</template>

<script>
import axios from 'axios';

export default {
    name: "blog-details",
    data() {
        return {
            blog: {}
        }
    },
    methods: {
        fetch_blog_details() {
            let blog_id = {blog_id: this.$route.params.id}
            axios.post("/fetch_blog_details", blog_id).then(({ data }) => {
                this.blog = data.data;
                // console.log(this.services);
            });
        },
    },
    created() {
        this.fetch_blog_details()
    }
}
</script>

<style>

</style>