<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container">
      <router-link to="/" class="navbar-brand">
        <img src="@/assets/media/image/logo.png" class="logo" alt="logo">
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/" class="nav-link active" aria-current="page">Home</router-link>
          </li>
          <li class="nav-item">
            <a href="/#services" class="nav-link">Our Services</a>
          </li>
          <li class="nav-item">
            <a href="/#about" class="nav-link">About Us</a>
          </li>
          <li class="nav-item">
            <router-link to="/blogs" class="nav-link">Blog</router-link>
          </li>
          <li class="nav-item">
            <a href="/#contact" class="nav-link">Contact Us</a>
          </li>
        </ul>
        <router-link to="/application" class="btn PrimaryButton">Fill The Application</router-link>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "navbar-layout",
  data() {
    return {
    };
  },
  methods: {
  },
  components: {
  },
  created() {
  },
};
</script>