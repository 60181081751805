<template>
    <section class="service_details">
        <div class="container">
            <img :src="service.image" :alt="service.title">
            <h1 class="title">{{service.title}}</h1>
            <p class="text" v-html="service.text"></p>
        </div>
    </section>
</template>

<script>
import axios from 'axios';

export default {
    name: "service-details",
    data() {
        return {
            service: {}
        }
    },
    methods: {
        service_details() {
            let service_id = {service_id: this.$route.params.id}
            axios.post("/service_details", service_id).then(({ data }) => {
                this.service = data.data;
                // console.log(this.services);
            });
        },
    },
    created() {
        this.service_details()
    }
}
</script>

<style>

</style>