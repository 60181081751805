<template>
  <section class="blogs_page">
    <div class="container">
        <div class="row">
            <div class="blog_header">
                <blogHeader :blogHeader="blogHeader" />
            </div>
            <div class="col-lg-4 col-md-6 col-12" v-for="(blog, index) in blogs.data" :key="index">
                <blogCard :blog="blog" />
            </div>
        </div>
        <div class="row">
            <pagination
            :data="blogs"
            class="mx-auto d-flex align-items-center justify-content-center pagination"
            @pagination-change-page="fetch_blogs"
            >
                <template v-slot:prev-nav>&lt;</template>
                <template v-slot:next-nav>&gt;</template>
            </pagination>
        </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import blogHeader from '@/components/include/cards/blogHeader.vue'
import blogCard from '@/components/include/cards/blogCard.vue'
import pagination from "laravel-vue-pagination";

export default {
    name: "blogs-page",
    data() {
        return {
            blogHeader: {},
            blogs: []
        }
    },
    components: {
        blogHeader,
        blogCard,
        pagination
    },
    methods: {
        fetch_blog_header() {
            axios.get("/fetch_blog_header").then(({ data }) => {
                this.blogHeader = data.data;
                // console.log(this.blogHeader);
            });
        },
        fetch_blogs(page = 1) {
            axios.get("/fetch_blogs?page=" + page).then(({ data }) => {
                this.blogs = data.data;
                console.log(this.blogs);
            });
        },
    },
    created() {
        this.fetch_blog_header();
        this.fetch_blogs()
    }
}
</script>

<style>

</style>