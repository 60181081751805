import { createRouter, createWebHistory } from "vue-router";
// import store from "../store/index.js";

import Home from "@/view/index.vue";
import blogs from "@/view/blogs.vue";
import blogDetails from "@/view/blog_details.vue";
import serviceDetails from "@/view/service_details.vue";
import Application from "@/view/application.vue";
import medicalCertificate from "@/view/medicalCertificate.vue";
import fitnessCertificate from "@/view/fitnessCertificate.vue";
import Terms from "@/view/terms.vue";
import Privacy from "@/view/privacy.vue";

const routes = [
  {
    path: "/",
    component: Home,
    name: "Home",
  },
  {
    path: "/blogs",
    component: blogs,
    name: "blogs",
  },
  {
    path: "/blog/:id",
    component: blogDetails,
    name: "blogDetails",
  },
  {
    path: "/service/:id",
    component: serviceDetails,
    name: "serviceDetails",
  },
  {
    path: "/application",
    component: Application,
    name: "Application",
  },
  {
    path: "/medical_certificate/:id",
    component: medicalCertificate,
    name: "medicalCertificate",
  },
  {
    path: "/fitness_certificate/:id",
    component: fitnessCertificate,
    name: "fitnessCertificate",
  },
  {
    path: "/terms",
    component: Terms,
    name: "Terms",
  },
  {
    path: "/privacy",
    component: Privacy,
    name: "Privacy",
  },
];

const router = createRouter({
  routes,
  history: createWebHistory(),
  hashbang: false,
  mode: "html5",
  linkActiveClass: "active",
  // linkExactActiveClass: "exact-active",
  scrollBehavior() {
    // Scroll to the top of the page
    return window.scrollTo({ top: 0, behavior: 'smooth' });
  },
});
// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (store.getters.isAuthenticated) {
//       next();
//       return;
//     }
//     next("/login");
//   } else {
//     next();
//   }
// });

// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.guest)) {
//     if (store.getters.isAuthenticated) {
//       next("/");
//       return;
//     }
//     next();
//   } else {
//     next();
//   }
// });

export default router;
