<template>
  <section class="header">
    <img :src="header.image" alt="header" />
    <div class="container d-flex align-items-center h-100">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="content">
            <h1 class="title">{{ header.title }}</h1>
            <p class="text">{{ header.text }}</p>
            <!-- <router-link to="/about" class="btn PrimaryButton">{{$t("Read More")}}</router-link> -->
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <searchCertificate />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import searchCertificate from "./search_certificate.vue";

export default {
  name: "header-home",
  data() {
    return {
      header: {},
    };
  },
  components: {
    searchCertificate
  },
  methods: {
    fetch_main_header() {
      axios.get("/fetch_main_header").then(({ data }) => {
        this.header = data.data;
        // console.log(this.header);
      });
    },
  },
  created() {
    this.fetch_main_header();
  },
};
</script>

<style></style>
