<template>
  <div id="app">
    <navbar />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import navbar from "@/components/layout/navbar.vue";
import Footer from "@/components/layout/footer.vue";

export default {
  name: "App",
  data() {
    return {};
  },
  created() {
    // Close inspect 
    // document.addEventListener("contextmenu", (event) => event.preventDefault());
    // document.onkeydown = function (e) {
    //   if (event.keyCode == 123) {
    //     return false;
    //   }
    //   if (e.ctrlKey && e.shiftKey && e.keyCode == "I".charCodeAt(0)) {
    //     return false;
    //   }
    //   if (e.ctrlKey && e.shiftKey && e.keyCode == "J".charCodeAt(0)) {
    //     return false;
    //   }
    //   if (e.ctrlKey && e.keyCode == "U".charCodeAt(0)) {
    //     return false;
    //   }
    // };
  },
  components: {
    navbar,
    Footer,
  },
};
</script>

<style></style>
