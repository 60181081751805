<template>
  <Header />
  <Services />
  <About />
  <Features />
  <Join />
  <Blogs />
  <Working />
</template>

<script>
import Header from '@/components/include/index/header.vue';
import Services from '@/components/include/index/services.vue';
import About from '@/components/include/index/about.vue';
import Features from '@/components/include/index/features.vue';
import Join from '@/components/include/index/join.vue';
import Blogs from '@/components/include/index/blogs.vue';
import Working from '@/components/include/index/working.vue';

export default {
  name: "home-page",
  data() {
    return {

    }
  },
  components: {
    Header,
    Services,
    About,
    Features,
    Join,
    Blogs,
    Working
  }
};
</script>
