<template>
  <section class="join">
    <div class="container">
        <h2 class="title">Join us now and get your certified certificate</h2>
        <router-link to="/application" class="btn SecondaryButton">Fill The Application</router-link>
    </div>
  </section>
</template>

<script>
export default {
    name: "join-home"
}
</script>

<style>

</style>