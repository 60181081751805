<template>
  <section class="services" id="services" ref="services">
    <div class="container">
        <h2 class="title">Comprehensive Occupational Health Services</h2>
        <p class="text">It's about more than just preventing accidents on the job - it's about ensuring that workers can perform their duties safely and effectively, without putting their health at risk.</p>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-12" v-for="(service, index) in services" :key="index">
                <serviceCard :service="service" />
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import serviceCard from '../cards/serviceCard.vue'

export default {
    name: "services-home",
    data() {
        return {
            services: []
        }
    },
    components: {
        serviceCard,
    },
    methods: {
        fetch_services() {
            axios.get("/fetch_services").then(({ data }) => {
                this.services = data.data;
                // console.log(this.services);
            });
        },
    },
    created() {
        this.fetch_services()
    }
}
</script>

<style>

</style>