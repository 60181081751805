<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <form class="form" v-on:submit.prevent="SubmitMedicalForm">
    <div class="row">
      <h4 class="section_title">General Information</h4>
      <div class="form-group">
        <label for="Passport">Passport / ID *</label>
        <input
          type="text"
          name=""
          id="Passport"
          class="form-control"
          v-model="Form.passport_id"
          placeholder="Passport / ID *"
          required
        />
      </div>
      <div class="form-group">
        <label for="Passport">Passport / ID Image *</label>
        <div class="file-upload-form">
          <label
            for="files"
            class="form-control d-flex align-items-center justify-content-center"
          >
            <i class="fa-solid fa-upload"></i>
            <div class="texts">
              <p class="title mb-2">Press To Add image</p>
              {{
                imageName == ""
                  ? "Please upload Passport / ID Image"
                  : imageName
              }}
            </div>
          </label>
          <input
            type="file"
            ref="file"
            id="files"
            hidden
            @change="previewImagePassport"
            accept="image/png, image/gif, image/jpeg, image/jpg"
          />
        </div>
        <!-- Button trigger modal -->
        <button
          type="button"
          v-if="passport_image_preview"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#passportModal"
        >
          Preview Passport / ID Image
        </button>

        <!-- Modal -->
        <div
          class="modal fade passportModal"
          id="passportModal"
          tabindex="-1"
          aria-labelledby="passportModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body">
                <div class="image-preview">
                  <img class="preview" :src="passport_image_preview" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="name">Full Name *</label>
        <input
          type="text"
          name=""
          id="name"
          class="form-control"
          v-model="Form.full_name"
          placeholder="Full Name *"
          required
        />
      </div>
      <div class="form-group">
        <label for="mobile">Mobile Number *</label>
        <input
          type="text"
          name=""
          id="mobile"
          class="form-control"
          v-model="Form.phone"
          placeholder="Mobile Number *"
          required
        />
        <!-- @input="validatePhoneNumber" -->
        <p v-if="validationError" class="error-message text-danger">
          {{ validationError }}
        </p>
      </div>
      <div class="form-group">
        <label for="email">E-mail Address *</label>
        <input
          type="email"
          name=""
          id="email"
          class="form-control"
          v-model="Form.email"
          placeholder="E-mail Address *"
          required
        />
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Date of Birth *</label>
        <datepicker
          id="dateOfBirth"
          :icon-color="color"
          :disabled-dates="{ to: new Date(1930, 10, 5), from: new Date() }"
          v-model="date_of_birth"
          placeholder="Date of Birth *"
        ></datepicker>
      </div>
      <div class="form-group">
        <label for="job">Job Title *</label>
        <input
          type="text"
          name=""
          id="job"
          class="form-control"
          v-model="Form.job_title"
          placeholder="enter your job title"
          required
        />
      </div>
      <div class="form-group">
        <label for="address">Address *</label>
        <input
          type="text"
          name=""
          id="address"
          class="form-control"
          v-model="Form.address"
          placeholder="enter address"
          required
        />
      </div>
      <div class="col-lg-6 col-md-6 col-12">
        <div class="form-group w-100">
          <label for="" class="d-block">Payment by company or personal</label>
          <div class="form-group type">
            <input
              type="radio"
              name="type"
              value="0"
              v-model="Form.type"
              id="personalRadio"
              hidden
            />
            <label for="personalRadio">Personal</label>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-12">
        <div class="form-group w-100">
          <label for="" class="d-block invisible">Company / Personal ? *</label>
          <div class="form-group type me-3">
            <input
              type="radio"
              name="type"
              value="1"
              v-model="Form.type"
              id="companyRadio"
              hidden
            />
            <label for="companyRadio">Company</label>
          </div>
        </div>
      </div>
      <div class="form-group" v-if="Form.type == 1">
        <label for="company">Company *</label>
        <input
          type="text"
          name=""
          id="company"
          class="form-control"
          v-model="Form.company"
          placeholder="enter company"
          required
        />
      </div>
      <h4 class="section_title">Medical History</h4>
      <div class="form-group">
        <label for="date">Last OEUK/OGUK Medical Exam *</label>
        <datepicker
          id="date"
          :icon-color="color"
          :disabled-dates="{ to: new Date(1930, 10, 5), from: new Date() }"
          v-model="last_medical_exam_date"
          placeholder="enter your medical exam"
        ></datepicker>
      </div>
      <div class="form-group-check">
        <label for="date"
          >Do you suffer from any of the following symptoms ? *</label
        >
        <div
          class="form-check"
          v-for="(symptom, index) in symptoms"
          :key="index"
        >
          <input
            class="form-check-input"
            v-model="symptom_ids"
            type="checkbox"
            :value="symptom.id"
            :id="`symptoms${symptom.id}`"
          />
          <label class="form-check-label" :for="`symptoms${symptom.id}`">
            {{ symptom.title }}
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="symptom_other"
            type="checkbox"
            value="0"
            id="symptoms0"
          />
          <label class="form-check-label" for="symptoms0"> Other </label>
        </div>
      </div>
      <div class="form-group" v-if="symptom_other">
        <label for="">Other symptom</label>
        <input
          type="text"
          name=""
          id=""
          class="form-control"
          v-model="symptom_texts"
          placeholder="Please enter other symptom"
        />
      </div>
      <div class="col-lg-6 col-md-6 col-12">
        <div class="form-group w-100">
          <label for="" class="d-block"
            >Do you use glasses or contact lenses for visual impairment ?
            *</label
          >
          <div class="form-group type">
            <input
              type="radio"
              name="Glasses"
              value="1"
              id="yesGlasses"
              v-model="Form.eye_use"
              hidden
            />
            <label for="yesGlasses">Yes</label>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-12">
        <div class="form-group w-100">
          <label for="" class="d-block invisible"
            >Do you use glasses or contact lenses for visual impairment ?
            *</label
          >
          <div class="form-group type me-3">
            <input
              type="radio"
              name="Glasses"
              value="2"
              id="noGlasses"
              v-model="Form.eye_use"
              hidden
            />
            <label for="noGlasses">No</label>
          </div>
        </div>
      </div>
      <div class="form-group-check">
        <label for="date"
          >Do you suffer from any of the following Chronic diseases? *</label
        >
        <div
          class="form-check"
          v-for="(disease, index) in diseases"
          :key="index"
        >
          <input
            class="form-check-input"
            type="checkbox"
            :value="disease.id"
            v-model="chronic_disease_ids"
            name="chronic_disease"
            :id="`diseases${disease.id}`"
          />
          <label class="form-check-label" :for="`diseases${disease.id}`">
            {{ disease.title }}
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            v-model="other_chronic_disease"
            name="chronic_disease"
            type="checkbox"
            value="0"
            id="chronic_disease0"
          />
          <label class="form-check-label" for="chronic_disease0"> Other </label>
        </div>
      </div>
      <div class="form-group" v-if="other_chronic_disease">
        <label for="">Other Chronic diseases</label>
        <input
          type="text"
          name=""
          id=""
          class="form-control"
          v-model="chronic_disease_texts"
          placeholder="Please enter other Chronic diseases"
        />
      </div>
      <div class="col-lg-6 col-md-6 col-12">
        <div class="form-group w-100">
          <label for="" class="d-block">Any Previous Operations ? *</label>
          <div class="form-group type">
            <input
              type="radio"
              name="Operations"
              value="1"
              id="yesOperations"
              v-model="Form.has_previous_operations"
              hidden
            />
            <label for="yesOperations">Yes</label>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-12">
        <div class="form-group w-100">
          <label for="" class="d-block invisible"
            >Any Previous Operations ? *</label
          >
          <div class="form-group type me-3">
            <input
              type="radio"
              name="Operations"
              value="0"
              id="noOperations"
              v-model="Form.has_previous_operations"
              hidden
            />
            <label for="noOperations">No</label>
          </div>
        </div>
      </div>
      <div class="form-group" v-if="Form.has_previous_operations == 1">
        <label for="example">Write example</label>
        <input
          type="text"
          name=""
          id="example"
          class="form-control"
          v-model="Form.example_operations"
          placeholder="enter example"
          required
        />
      </div>
      <div class="form-check form-check-declare">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="accept"
          true-value="1"
          false-value="0"
          id="declare"
        />
        <label class="form-check-label w-100" for="declare">
          I declare that all information above is correct.
        </label>
      </div>
      <button type="submit" class="btn PrimaryButton">Submit</button>
    </div>
  </form>
</template>

<script>
import Datepicker from "vuejs3-datepicker";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import Loading from "@/components/include/LottieFiles/loading.vue";

export default {
  data() {
    return {
      symptoms: [],
      diseases: [],
      Form: {
        certificate_type: 1,
        passport_id: "",
        passport_image: "",
        full_name: "",
        phone: "",
        email: "",
        job_title: "",
        address: "",
        company: "",
        type: "",
        eye_use: "",
        has_previous_operations: "",
        example_operations: "",
      },
      loading: false,
      symptom_ids: [],
      symptom_texts: [],
      chronic_disease_ids: [],
      chronic_disease_texts: [],
      date_of_birth: "",
      last_medical_exam_date: "",
      accept: "",
      symptom_other: "",
      other_chronic_disease: "",
      passport_image_preview: "",
      imageName: "",
      validationError: "",
    };
  },
  setup() {
    const color = "#0779b8";
    const height = "18";
    const width = "18";

    return {
      color,
      width,
      height,
    };
  },
  components: {
    Datepicker,
    Loading,
  },
  methods: {
    // validatePhoneNumber() {
    //   // Define a regular expression pattern for phone number validation
    //   const phonePattern = /^\d{11}$/; // Assuming the phone number should have 10 digits

    //   if (!phonePattern.test(this.Form.phone)) {
    //     this.validationError = "Invalid phone number";
    //   } else {
    //     this.validationError = "";
    //   }
    // },

    // image
    previewImagePassport(event) {
      const file = event.target.files[0];
      this.imageName = file.name;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.Form.passport_image = file;
        this.passport_image_preview = reader.result;
        // console.log(this.Form.passport_image)
      };
    },
    // image
    fetch_symptoms() {
      axios.get("/fetch_symptoms").then(({ data }) => {
        this.symptoms = data.data;
        // console.log(this.symptoms);
      });
    },
    fetch_chronic_diseases() {
      axios.get("/fetch_chronic_diseases").then(({ data }) => {
        this.diseases = data.data;
        // console.log(this.hours);
      });
    },
    SubmitMedicalForm() {
      this.loading = true; //the loading begin
      const formData = new FormData();
      Object.entries(this.Form).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append(
        "last_medical_exam_date",
        moment(this.last_medical_exam_date).format("YYYY/MM/DD")
      );
      formData.append(
        "date_of_birth",
        moment(this.date_of_birth).format("YYYY/MM/DD")
      );
      for (let index = 0; index < this.symptom_ids.length; index++) {
        formData.append(`symptom_ids[${index}]`, this.symptom_ids[index]);
      }
      formData.append(`symptom_texts[]`, this.symptom_texts);
      for (let index = 0; index < this.chronic_disease_ids.length; index++) {
        formData.append(
          `chronic_disease_ids[${index}]`,
          this.chronic_disease_ids[index]
        );
      }
      formData.append(`chronic_disease_texts[]`, this.chronic_disease_texts);

      if (this.Form.certificate_type == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select Certificate type",
          showConfirmButton: true,
        });
        this.loading = false;
      } else if (this.passport_image_preview == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select Upload Passport / ID image",
          showConfirmButton: true,
        });
        this.loading = false;
      } else if (this.date_of_birth == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select Date of Birth",
          showConfirmButton: true,
        });
        this.loading = false;
      } else if (this.Form.type == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select Type Company / Personal",
          showConfirmButton: true,
        });
        this.loading = false;
      } else if (this.last_medical_exam_date == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select Medical Exam Date",
          showConfirmButton: true,
        });
        this.loading = false;
      } else if (!this.symptom_ids.length && this.symptom_texts == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select symptoms",
          showConfirmButton: true,
        });
        this.loading = false;
      } else if (
        !this.chronic_disease_ids.length &&
        this.chronic_disease_texts == ""
      ) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select Chronic diseases",
          showConfirmButton: true,
        });
        this.loading = false;
      } else if (this.Form.eye_use == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select use glasses or contact lenses",
          showConfirmButton: true,
        });
        this.loading = false;
      } else if (this.Form.has_previous_operations == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select Previous Operations",
          showConfirmButton: true,
        });
        this.loading = false;
      } else if (this.accept == 0) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please select you declare that all information",
          showConfirmButton: true,
        });
        this.loading = false;
      } else {
        axios
          .post("/send_request", formData)
          .then(({ data }) => {
            this.data = data;
            this.status = data.status;
            this.message = data.message;
            // console.log(data);
            if (this.status == true) {
              Swal.fire({
                title:
                  "Thanks For your Application, we will contact you soon with further details.",
                text: "Modal with a custom image.",
                imageUrl:
                  "https://cdn-icons-png.flaticon.com/512/5610/5610944.png",
                imageWidth: 110,
                imageHeight: 110,
                imageAlt: "Custom image",
                html:
                  `<p class="title mb-2">Serial No : ${this.data.data.serial_no}</p>` +
                  '<p class="text">Note: Examination will take 1 hour.</p>' +
                  '<div class="email"><p class="text">If you have any Questions, contact us on: </p><a href="mailto:info@pmgoccuhealth.com">info@pmgoccuhealth.com</a></div> ',
                showConfirmButton: false,
              });
              this.$router.push({ path: "/" });
            }
          })
          .catch((error) => {
            Swal.fire({
              type: "error",
              position: "center",
              icon: "error",
              title: error.response.data.message,
            });
            // console.log(error);
            // this.$router.push({ path: "/teachers" });
            this.loading = false;
          })
          .finally(() => (this.loading = false));
        {
          //Perform action in always
        }
      }
    },
  },
  created() {
    this.fetch_symptoms();
    this.fetch_chronic_diseases();
  },
};
</script>

<style></style>
